# Carrés colorés

S'il y a 5 carrés rouge, cliquer sur un bleu.

Sinon, s'il y a 3 carrés rouge, cliquer sur le deuxième carré rouge dans l'ordre de lecture de gauche à droite puis de haut en bas.

Sinon, s'il y a un nombre pair de carrés bleus : 
- s'il n'y a pas de carré rouge dans la ligne du bas, cliquer sur le carré central
- sinon s'il y a un carré rouge dans la ligne du bas, cliquer sur un carré bleu
- sinon cliquer sur le carré à gauche du carré du haut

Sinon s'il n'y a aucun rouge, cliquer sur le carré central.

Sinon, cliquer sur un carré rouge.