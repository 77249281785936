import { Vect } from "2d-coord";
import { socket } from "./app";
import { playSound } from "./audio";
import { EnemyType } from "./guard";
import { GameParametor } from "./map_generator";
import { World } from "./world";

let isGamepadAxisMoved = false;

let pressedButtons = new Set<number>();

export function checkGamePad(gamepad: Gamepad, world: World, canvas: HTMLCanvasElement){
    // if (!gamepad) return;
    // gamepad = navigator.getGamepads()[gamepad.index];

    // RB
    if (gamepad.buttons[5].pressed && pressedButtons.has(5) == false) {
        pressedButtons.add(5);
    }
     // Petit à gauche
     if (gamepad.buttons[6].pressed && pressedButtons.has(6) == false) {
        pressedButtons.add(6);
        const msg = {name: "generate_world"};
        const params = new Array<GameParametor>();
        params.push(new GameParametor("nb_maps", "Nombre d'étages", 1,3,5));
        params.push(new GameParametor("map_size", "Taille de chaque étage", 4, world.gameMaps[0].grid_size, 20));
        params.push(new GameParametor("nb_guards", "Nb gardes", 0, world.getNbEnemies(EnemyType.Guard), 6));
        params.push(new GameParametor("nb_ghosts", "Nb fantomes", 0, world.getNbEnemies(EnemyType.Ghost), 6));
        params.push(new GameParametor("nb_pions", "Nb pions", 0, world.getNbEnemies(EnemyType.Pion), 6));
        params.push(new GameParametor("is_discovered", "Découverte", 0, 0, 1));
        for (const param of params){
            msg[param.name] = param.value;
        }
        socket.send(JSON.stringify(msg))
    }

   

    // A key
    if (gamepad.buttons[0].pressed && pressedButtons.has(0) == false) {
        pressedButtons.add(0);
        world.localPlayerRequestActivation();
    }
    
    // B key
    if (gamepad.buttons[1].pressed && pressedButtons.has(1) == false) {
        pressedButtons.add(1);
        
    }

    // deselect all unpressed buttons
    for ( let i = 0 ; i < gamepad.buttons.length ; i ++){
        if (gamepad.buttons[i].pressed == false && pressedButtons.has(i)){
            pressedButtons.delete(i);
        }
    }
  
    // axis management
    const v = new Vect(gamepad.axes[0], gamepad.axes[1]);

    if (v.norm() > 0.15){
        playSound("walk");

        isGamepadAxisMoved = true;
        const myPlayer = world.players.get(world.myId);
        if (myPlayer){
        socket.send(JSON.stringify({ name: "gamepad_dir", x: v.x, y: v.y }))
        // socket.send(JSON.stringify({ name: "mousedown", x: myPlayer.pos.x + v.x, y: myPlayer.pos.y + v.y }))
        }
    } else {
        if (isGamepadAxisMoved){
            isGamepadAxisMoved = false;
        socket.send(JSON.stringify({ name: "stopmove"}))
        }
    }
}