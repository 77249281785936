import { socket } from "./app";
import { EnemyType } from "./guard";
import { World } from "./world";

export class GameParametor {
    name: string;
    humanName: string;
    min: number;
    defaultValue: number;
    value: number;
    max: number;

    constructor(name: string, humanName: string, min: number, defaultValue: number, max: number){
        this.name = name;
        this.humanName = humanName;
        this.min = min;
        this.defaultValue = defaultValue;
        this.value = defaultValue;
        this.max = max;
    }
}

function setParamValue(params: Array<GameParametor>, paramName: string, value: number){
    console.log(paramName, value);
    for (const param of params){
        if (param.name == paramName){
            param.value = value;
            const input = document.getElementById("param_" + paramName) as HTMLInputElement;
            input.value = param.value.toString();
        }
    }
}

export function launchMapGenerator(world: World){
    const div = document.createElement("div");
    div.id = "mapGenerator";
    div.innerHTML = "<h1>Générer un monde</h1>";
    document.body.appendChild(div);

    const paramsDiv = document.createElement("div");
    paramsDiv.classList.add("paramsContainer");
    div.appendChild(paramsDiv);



    const params = new Array<GameParametor>();
    params.push(new GameParametor("nb_maps", "Nombre d'étages", 1,3,5));
    params.push(new GameParametor("map_size", "Taille de chaque étage", 4, world.gameMaps[0].grid_size, 20));
    params.push(new GameParametor("nb_guards", "Nb gardes", 0, world.getNbEnemies(EnemyType.Guard), 6));
    params.push(new GameParametor("nb_ghosts", "Nb fantomes", 0, world.getNbEnemies(EnemyType.Ghost), 6));
    params.push(new GameParametor("nb_pions", "Nb pions", 0, world.getNbEnemies(EnemyType.Pion), 20));
    params.push(new GameParametor("is_discovered", "Découverte", 0, 0, 1));


    for (const param of params){
        const name = document.createElement("label");
        name.innerHTML = param.humanName;
        name.htmlFor = param.name;
        paramsDiv.appendChild(name);

        const input = document.createElement("input");
        input.name = "param_" + param.name;
        input.id = input.name;
        input.type = "number";
        input.value = param.value.toString();
        input.min = param.min.toString();
        input.max = param.max.toString();

        input.onchange = () => {
            param.value = parseInt(input.value);
        }
        paramsDiv.appendChild(input);

    }

    // Generate button
    const buttonGenerate = document.createElement("button");
    buttonGenerate.textContent = "Générer";
    div.appendChild(buttonGenerate);

    buttonGenerate.onclick = () => {
        const msg = {name: "generate_world"};
        for (const param of params){
            msg[param.name] = param.value;
        }
        socket.send(JSON.stringify(msg))
        div.remove();
    }

    // Generate: easy map
    const buttonEasy = document.createElement("button");
    buttonEasy.textContent = "Easy";
    div.appendChild(buttonEasy);

    buttonEasy.onclick = () => {
        setParamValue(params, "map_size", 4);
        setParamValue(params, "nb_guards", 0);
        setParamValue(params, "nb_ghosts", 0);
        setParamValue(params, "nb_pions", 0);
        setParamValue(params, "is_discovered", 1);
    }

}