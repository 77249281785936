export enum CaseType {
    Normal = "Normal",
    LowSpeed = "LowSpeed",
    Camera = "Camera",
    Stop = "Stop",
    Explode = "Explode",
    Spawn = "Spawn"
}


export class Case {
    case_type: CaseType;
    up: boolean;
    down: boolean;
    left: boolean;
    right: boolean;
    discovered: boolean;

    constructor(caseType: CaseType, up: boolean, down: boolean, left: boolean, right: boolean, discovered: boolean){
        this.case_type = caseType;
        this.up = up;
        this.down = down;
        this.left = left;
        this.right = right;
        this.discovered = discovered;
    }
}

export class CaseExplode extends Case {
    state: number;

    constructor(caseType: CaseType, up: boolean, down: boolean, left: boolean, right: boolean, discovered: boolean, state: number){
        super(caseType, up, down, left, right, discovered);
        this.state = state;
    }
}

export class CaseCamera extends Case {
    active: boolean;

    constructor(caseType: CaseType, up: boolean, down: boolean, left: boolean, right: boolean, discovered: boolean, active: boolean){
        super(caseType, up, down, left, right, discovered);
        this.active = active;
    }
}


export function fromRawCase(rawCase): Case {
    const type = rawCase.case_type.type as CaseType;

    switch(type){
        case CaseType.Explode : {
            const state = rawCase.case_type.state as number;
            return new CaseExplode(type, rawCase.up, rawCase.down, rawCase.left, rawCase.right, rawCase.discovered, state);
        }
        case CaseType.Camera : {
            const active = rawCase.case_type.active as boolean;
            return new CaseCamera(type, rawCase.up, rawCase.down, rawCase.left, rawCase.right, rawCase.discovered, active);
        }
        default : {
            return new Case(type, rawCase.up, rawCase.down, rawCase.left, rawCase.right, rawCase.discovered);
        }
    }
}


