import { images } from "./images_import";
import { Player } from "./player";


export function setupCapacityDiv(){
    const div = document.createElement("div");
    div.id = "capacity";
    document.body.appendChild(div);
}

let progressInterval: number | null = null;




export function resetCapacityDiv(myPlayer: Player){
    const div = document.getElementById("capacity");
    if (div){
        div.replaceChildren();
        if (myPlayer.capacity_wall_breaker){
            const img = images.get("IconHammer");
            const newImg = document.createElement("img");
            div.appendChild(newImg);
            if (img){
                newImg.src = img.src;
                if ( myPlayer.can_break_wall){
                    newImg.classList.add("capacity_ok");
                } else {
                    newImg.classList.add("capacity_no");
                }
            }

            const progressBar = document.createElement("div");
            progressBar.classList.add("progressBar");
            div.appendChild(progressBar);
            const ratio = myPlayer.capacityCoolDownRatio();
            progressBar.style.width = ratio.toString() + "%"; 

            if (typeof progressInterval === "number"){
                clearInterval(progressInterval);
                progressInterval = null;
            }
            progressInterval = setInterval(() => {
                    const ratio = myPlayer.capacityCoolDownRatio();
                    progressBar.style.width = ratio.toString() + "%"; 
            }, 100);
            


        }

    }
}