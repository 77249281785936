import { Coord } from "2d-coord";
import { PuzzleType } from "./puzzle";

export enum ObjectType {
    Treasure = "Treasure",
    Key = "Key",
    SpeedPotion = "SpeedPotion",
    Task = "Task",
    Tree = "Tree",
    Teleporter = "Teleporter",
    TreasureOpen = "TreasureOpen",
    Book = "Book",
    Machine = "Machine",
    Bague = "Bague",
    UnfreezePotion = "UnfreezePotion",
    Hammer = "Hammer"
}

export function info(type: ObjectType){
    switch(type){
        case ObjectType.SpeedPotion: {
            return "Potion de vitesse. <br> Usage unique. <br> Durée : 10s."
        }
        case ObjectType.UnfreezePotion: {
            return "Potion de dégel. <br> Usage unique. <br> Pour soi ou pour un autre joueur."
        }
        case ObjectType.Bague: {
           return "Bague. <br> Attire le garde le plus proche. <br> Cool down : 3s"
        } 
        case ObjectType.Key: {
            return "Clef. <br> Ouvre les coffres."
         } 
        case ObjectType.Book: {
            return "Guide des quêtes. <br> Pour résoudre les puzzles."
        }
        case ObjectType.Hammer: {
            return "Marteau. <br> Usage unique. Détruit un mur."
        }
        default :{
            return "";
        }
    }
}

export function object_from_string(str: string): ObjectType | undefined {
    if (str == "Treasure") {
        return ObjectType.Treasure;
    } else if (str == "Key") {
        return ObjectType.Key;
    } else if (str == "SpeedPotion") {
        return ObjectType.SpeedPotion;
    } else if (str == "Task") {
        return ObjectType.Task;
    } else if (str == "Tree") {
        return ObjectType.Tree;
    } else if (str == "Teleporter") {
        return ObjectType.Teleporter;
    } else if (str == "TreasureOpen") {
        return ObjectType.TreasureOpen;
    } else if (str == "Book") {
        return ObjectType.Book;
    } else if (str == "Machine") {
        return ObjectType.Machine;
    } else if (str == "Bague") {
        return ObjectType.Bague;
    } else if(str == "UnfreezePotion"){
        return ObjectType.UnfreezePotion;
    } else if(str == "Hammer"){
        return ObjectType.Hammer;
    }
    return undefined;
}





export class MapObject {
    pos: Coord;
    case_coord: Coord;
    name: ObjectType;
    imgSrc: string;
    coolDownStart: number;
    coolDownEnd: number;
    progressInterval: number | null; // ID of the TimeInterval

    constructor(pos: Coord, caseCoord: Coord, name: ObjectType, coolDownStart: number, coolDownEnd: number) {
        this.pos = pos;
        this.case_coord = caseCoord;
        this.name = name;
        this.imgSrc = name;
        this.coolDownStart = coolDownStart;
        this.coolDownEnd = coolDownEnd;
        this.progressInterval = null;
    }

    /**
     * Returns the CoolDownRatio of the object between 0 and 100.
     * Returns 100 if the ratio is >= 100 so that we dont see the progressBar. 
     */
    getCoolDownRatio() {
        const now = new Date();
        const nowMs = now.getTime();
        const duration = (this.coolDownEnd - this.coolDownStart);
        const ratio = 100 * (nowMs - this.coolDownStart) / duration;

        if (ratio < 0) {
            return 0;
        } else if (ratio >= 100) {
            return 100;
        } else {
            return ratio;
        }
    }

    static from_raw_object(rawObject): MapObject | undefined {
        const pos = new Coord(rawObject.pos.x, rawObject.pos.y);
        const caseCoord = new Coord(rawObject.case_coord.x, rawObject.case_coord.y);
        const type = object_from_string(rawObject.name.type);
        if (type) {
            return new MapObject(pos, caseCoord, type, rawObject.cool_down_start, rawObject.cool_down_end);
        } else {
            return undefined;
        }
    }

}



export class MapObjectBook extends MapObject {
    text: string;

    constructor(pos: Coord, caseCoord: Coord, type: ObjectType, coolDownStart: number, coolDownEnd: number, text: string) {
        super(pos, caseCoord, type, coolDownStart, coolDownEnd);
        this.text = text;
    }
}

export class MapObjectTask extends MapObject {
    done: boolean;
    puzzle: PuzzleType;

    constructor(pos: Coord, caseCoord: Coord, type: ObjectType, coolDownStart: number, coolDownEnd: number, done: boolean, puzzle: PuzzleType) {
        super(pos, caseCoord, type, coolDownStart, coolDownEnd);
        this.done = done;
        this.puzzle = puzzle;
        if (done) {
            this.imgSrc = "TaskDone";
        } else {
            this.imgSrc = "Task";
        }
    }
}

export class MapObjectTeleporter extends MapObject {
    id: number;
    color: string;
    peerId: number;

    constructor(pos: Coord, caseCoord: Coord, type: ObjectType, coolDownStart: number, coolDownEnd: number, id: number, color: string, peerId: number) {
        super(pos, caseCoord, type, coolDownStart, coolDownEnd);
        this.id = id;
        this.color = color;
        this.peerId = peerId;

        if (color == "Blue") {
            this.imgSrc = "Teleporter";
        } else if (color == "Red") {
            this.imgSrc = "TeleporterRed";
        } else if (color == "Green") {
            this.imgSrc = "TeleporterGreen";
        } else {
            this.imgSrc = "Teleporter";
        }
    }
}




export function fromRawObject(rawObject): MapObject | undefined {
    const pos = new Coord(rawObject.pos.x, rawObject.pos.y);
    const caseCoord = new Coord(rawObject.case_coord.x, rawObject.case_coord.y);
    const type = object_from_string(rawObject.name.type);

    switch (type) {
        case ObjectType.Book: {
            const text = rawObject.name.text as string;
            return new MapObjectBook(pos, caseCoord, type, rawObject.cool_down_start, rawObject.cool_down_end, text);
        }
        case ObjectType.Teleporter: {
            return new MapObjectTeleporter(pos, caseCoord, type, rawObject.cool_down_start, rawObject.cool_down_end, rawObject.name.id, rawObject.name.color, rawObject.name.peer_id);
        }
        case ObjectType.Task: {
            return new MapObjectTask(pos, caseCoord, type, rawObject.cool_down_start, rawObject.cool_down_end, rawObject.name.done, rawObject.name.puzzle as PuzzleType);
        }
        default: {
            return MapObject.from_raw_object(rawObject);
        }
    }
}