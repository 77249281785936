import {socket} from "./app";
import * as marked from 'marked';
import ManPageColoredSquares from './puzzles/man_pages/colored_squares.md';
import ManPageSort from './puzzles/man_pages/sort.md';
import ManMaze from './puzzles/man_pages/maze.md';
import { launchPuzzleCurve } from "./puzzles/puzzle_curve";
import { launchPuzzleLeaves } from "./puzzles/puzzle_leaves";
import { launchMachinePuzzle } from "./puzzles/puzzle_colored_squares";

import { launchPuzzleMaze } from "./puzzles/puzzle_maze";
import { launchPuzzleSort } from "./puzzles/puzzle_sort";
import { launchPuzzleGrid } from "./puzzles/puzzle_grid";

// puzzleImages["mazeMap"];

export enum PuzzleType {
  Leaves = "Leaves",
  ColoredSquares = "ColoredSquares",
  Curve = "Curve",
  Maze = "Maze",
  Sort = "Sort",
  Grid = "Grid"
}

export function launchPuzzleMeta(puzzle: PuzzleType){
  switch(puzzle){
    case PuzzleType.Curve: {
      launchPuzzleCurve();
      break;
    }
    case PuzzleType.Leaves: {
      launchPuzzleLeaves();
      break;
    }
    case PuzzleType.ColoredSquares: {
      launchMachinePuzzle();
      break;
    }
    case PuzzleType.Maze: {
      launchPuzzleMaze();
      break;
    }
    case PuzzleType.Sort: {
      launchPuzzleSort();
      break;
    }
    case PuzzleType.Grid: {
      launchPuzzleGrid();
      break;
    }
  }
}


// ---------------
// Pop Up

const book: Array<string> = [
  marked.marked(ManPageColoredSquares),
  marked.marked(ManPageSort),
  marked.marked(ManMaze)];
let indexOpenPage = 0;

export function launchBookPopUp(text: string){
  const div = document.createElement("div");
  document.body.appendChild(div);
  div.id = "book";

  const content = document.createElement("div");
  content.id = "bookContent";
  div.appendChild(content);

  content.innerHTML = book[indexOpenPage];
  content.onclick = () => {
    div.remove();
  }


  const previousPageButton = document.createElement("div");
  previousPageButton.id = "previousPageButton";
  div.appendChild(previousPageButton);
  if (indexOpenPage == 0){
    previousPageButton.style.display = "none";
  }

  previousPageButton.onclick = (e) => {
    if (indexOpenPage > 0){
      indexOpenPage --;
      if (indexOpenPage == 0){
        previousPageButton.style.display = "none";
      }
      nextPageButton.style.display = "block";
      content.innerHTML = book[indexOpenPage];
    }
  }

  const nextPageButton = document.createElement("div");
  nextPageButton.id = "nextPageButton";
  div.appendChild(nextPageButton);
  if (indexOpenPage == book.length-1){
    nextPageButton.style.display = "none";
  }

  nextPageButton.onclick = (e) => {
    if (indexOpenPage+1 < book.length){
      indexOpenPage ++;
      if (indexOpenPage == book.length-1){
        nextPageButton.style.display = "none";
      }
      previousPageButton.style.display = "block";
      content.innerHTML = book[indexOpenPage];
    }
  }

}
  
  
  



// Puzzle
  
  
  export function launchPuzzle(){
    const div = document.createElement("div");
    document.body.appendChild(div);
    div.classList.add("puzzle");
  
    let number_solved = 0;
    const number_to_solve = 3;
  
    for (let i = 0 ; i < number_to_solve ; i ++){
      const square = document.createElement("div");
      div.appendChild(square);
      square.classList.add("puzzle-item-square");
  
      square.onclick = function () {
        if ( square.classList.contains("solved") == false ){
          square.style.backgroundColor = "black";
          square.classList.add("solved");
          number_solved += 1;
          if (number_solved == number_to_solve){
            div.remove();
            socket.send(JSON.stringify({ name: "activate" }));
          }
        }
      }
    }
  }
  
  