import { socket } from "./app";
import { images } from "./images_import";
import { Player, PlayerType } from "./player";
import PACKAGE from "../../package.json";

function iconSrc(type: PlayerType): string{
    if (type == PlayerType.Frodon){
        return "FrodonIcon";
    } else if (type == PlayerType.Arwen){
        return "ArwenIcon";
    } else if (type == PlayerType.Gandalf){
        return "GandalfIcon";
    } else if (type == PlayerType.Sam){
        return "SamIcon";
    } else if (type == PlayerType.Gollum){
        return "GollumIcon";
    }
    return ""
}

function titleFromPlayerType(type: PlayerType): string{
    if (type == PlayerType.Frodon){
        return "Decouvreur";
    } else if (type == PlayerType.Arwen){
        return "Medecin";
    } else if (type == PlayerType.Gandalf){
        return "Anti-alarme";
    } else if (type == PlayerType.Gollum){
        return "Rapide";
    } else if (type == PlayerType.Sam){
        return "La brute";
    }
    
    return ""
}

function descriptionFromPlayerType(type: PlayerType): string{
    if (type == PlayerType.Frodon){
        return "Découvre les cases autour de lui.";
    } else if (type == PlayerType.Arwen){
        return "Peut dégeler les autres joueurs.";
    } else if (type == PlayerType.Gandalf){
        return "N'active pas les alarmes.";
    } else if (type == PlayerType.Gollum){
        return "N'active pas les ralentissements.";
    } else if (type == PlayerType.Sam){
        return "Peut casser des murs.";
    }
    return ""
}

function setupPlayerChoice(str: PlayerType){
    const div = document.getElementById("playerSelector");
    if (div){
        const playerDiv = document.createElement("div");
        div.appendChild(playerDiv);
        playerDiv.classList.add("playerChoice");
        const title = document.createElement("div");
        title.classList.add("title");
        title.innerHTML = titleFromPlayerType(str);
        playerDiv.appendChild(title);
        const img = document.createElement("img");
        const imgsrc = images.get(iconSrc(str));
        if (imgsrc){
            img.src = imgsrc.src;
            playerDiv.appendChild(img);
        }

        const description = document.createElement("div");
        description.innerHTML = descriptionFromPlayerType(str);
        playerDiv.appendChild(description);

        playerDiv.onclick = () => {
            choose(str);
            div.style.display = "none";
        }
    }
}

function choose(type: PlayerType){
    console.log("Send choose_player_type", type );
    socket.send(JSON.stringify({ name: "choose_player_type", type: type }));
}

export function setupPlayerSelector(){
    const div = document.getElementById("playerSelector");
    if (div){
        div.innerHTML = "<h1>Choix de le classe</h1>";
        setupPlayerChoice(PlayerType.Frodon);
        setupPlayerChoice(PlayerType.Arwen);
        setupPlayerChoice(PlayerType.Gandalf);
        setupPlayerChoice(PlayerType.Gollum);
        setupPlayerChoice(PlayerType.Sam);

        const clientVersion = document.createElement("div");
        clientVersion.classList.add("clientVersion");
        div.appendChild(clientVersion);
        clientVersion.innerHTML = "client-version: " + PACKAGE.version;
    }
}