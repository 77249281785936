import { Coord } from "2d-coord";
import { images } from "../images_import";

export class DragContainer{
    div: HTMLElement;
    draggedIndex: number | null;
    mouseLastPos: Coord | null;
    elements: Array<DragableElement>;
    afterMouseMove: () => void;

    constructor(div: HTMLElement){
        this.div = div;
        this.draggedIndex = null;
        this.mouseLastPos = null;
        this.elements = new Array();
        this.afterMouseMove = () => {};

        div.onmousedown = (e) => {
            this.mouseLastPos = new Coord( e.pageX, e.pageY);
        }

        div.onmousemove = (e) => {
            if (typeof this.draggedIndex === "number"){
                if (this.mouseLastPos !== null){
                    const mouse_new_pos = new Coord(e.pageX, e.pageY);
                    this.elements[this.draggedIndex].translate(this.mouseLastPos, mouse_new_pos);
                    this.mouseLastPos = mouse_new_pos;
                    this.afterMouseMove();
                }
            }
        }

        div.onmouseup = (e) => {
            this.mouseLastPos = null;
            this.draggedIndex = null;
        }
    }

    add(div: HTMLElement, pos: Coord){
        let index = this.elements.length;
        this.div.appendChild(div);
        this.elements.push(new DragableElement(this, index, pos, div));
    }

    addImage(basicImgName: string, className: string, pos: Coord){
        const img = document.createElement("img");
        img.classList.add(className);
        const basicImg = images.get(basicImgName);
        if (basicImg){
            img.src = basicImg.src;
        }
        this.add(img, pos);
    }

    lastElt(): DragableElement {
        return this.elements[this.elements.length-1];
    }

    
}

export class DragableElement{
    container: DragContainer;
    index: number;
    pos: Coord;
    div: HTMLElement;

    constructor(container: DragContainer, index: number, pos: Coord, div: HTMLElement){
        this.container = container;
        this.index = index;
        this.pos = pos;
        this.div = div;
        this.div.onload = () => {
            this.updateDivPos();
        }
        this.updateDivPos();

        div.onmousedown = (e) => {
            this.container.draggedIndex = this.index;
        }

        div.ondragstart = (e) => {
            e?.preventDefault();
        }
    }

    updateDivPos(){
        this.div.style.top = (30+this.pos.y - this.div.clientHeight/2).toString();
        this.div.style.left = (30+this.pos.x - this.div.clientWidth/2).toString();
    }

    translate(src: Coord, dest: Coord){
        this.pos.x += dest.x - src.x;
        this.pos.y += dest.y - src.y;
        this.updateDivPos();
    }
}