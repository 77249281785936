// --- Avant --- 
/*
on le charge dans index.html
pas besoin de .mp3 dans global.d.ts

// pour play
const audio = document.getElementById("audioActivate") as HTMLAudioElement;
if (audio) audio.play();
*/



// import sounds from 'url:../sounds/*.mp3'; // ça marche pas
import soundWalk from 'url:../sounds/walk.mp3';
import soundActivate from 'url:../sounds/activate.mp3';
import soundBreakWall from 'url:../sounds/break_wall.mp3';
import soundGameOver from 'url:../sounds/game_over.mp3';
import soundOpenChest from 'url:../sounds/open_chest.mp3';
import soundFreezePlayer from 'url:../sounds/freeze_player.mp3';
import soundTriggerTrap from 'url:../sounds/trigger_trap.mp3';


let audioElements = new Map<string, HTMLAudioElement>();

export function setupAudioElements(){
    console.log("Setup Audio Elements :");

    audioElements.set("walk", new Audio(soundWalk));
    audioElements.set("activate", new Audio(soundActivate));
    audioElements.set("break_wall", new Audio(soundBreakWall));
    audioElements.set("open_chest", new Audio(soundOpenChest));
    audioElements.set("game_over", new Audio(soundGameOver));
    audioElements.set("freeze_player", new Audio(soundFreezePlayer));
    audioElements.set("trigger_trap", new Audio(soundTriggerTrap));




    // for (const soundName in sounds){
    //     console.log("sound: ", soundName);
    //     // const audioElement: HTMLAudioElement = document.createElement("audio");
    //     // // audioElement.setAttribute("type", "audio/mpeg");
    //     // audioElement.type = "audio/mpeg";
    //     // audioElement.src =  "sounds/walk.mp3";
    //     // document.body.appendChild(audioElement);
    //     // audioElement.id = "audio" + soundName;

        

    //     const audioElement: HTMLAudioElement = document.createElement('audio');
    //     const sourceElement: HTMLSourceElement = document.createElement('source');

    //     sourceElement.src = soundName;
    //     sourceElement.type = 'audio/mpeg';

    //     audioElement.appendChild(sourceElement);
    //     document.body.appendChild(audioElement);

    //     audioElement.play();


    //     audioElements.set(soundName, audioElement);
    // }
}




export function playSound(soundName: string){
    const audioElement = audioElements.get(soundName);
    if ( audioElement ){
        audioElement.play();
    }
}