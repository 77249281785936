import { Coord } from "2d-coord";
import { mega_counter } from "./app";
import { images } from "./images_import";
import { Player } from "./player";
import enemiesImages from '../img/enemies/*.png';

export enum EnemyType {
    Guard = "Guard",
    Ghost = "Ghost",
    Pion = "Pion"
}

export class Guard {
    pos: Coord;
    target: Coord;
    speed: number;
    caseCoord: Coord;
    path: Array<Coord>;
    active: boolean;
    enemyType: EnemyType;

    // Client attributes
    canvas: HTMLCanvasElement;
    ctx: CanvasRenderingContext2D;
    img: HTMLImageElement;

    constructor(pos: Coord, target: Coord, speed: number, caseCoord: Coord, path: Array<Coord>, active: boolean, enemyType: EnemyType, canvas: HTMLCanvasElement, ctx: CanvasRenderingContext2D){
        this.pos = pos;
        this.target = target;
        this.speed = speed;
        this.caseCoord = caseCoord;
        this.path = path;
        this.active = active;
        this.enemyType = enemyType;
        this.canvas = canvas;
        this.ctx = ctx;
        this.img = document.createElement("img");
        this.img.src = enemiesImages[this.enemyType];
    }
    
    draw(myPlayer: Player){
        // const image = images.get(this.enemyType);
        if (true){
            const x = this.pos.x + this.canvas.width/2 - myPlayer.pos.x - 48/2;
            const y = this.pos.y + this.canvas.height/2 - myPlayer.pos.y - 48;
        
            let c = mega_counter %3;

            if (this.target.distTo(this.pos) < 2){
                this.ctx.drawImage(this.img, 48, 0, 48,48, x, y, 48,48);
            } else {
                if ( this.target.x > this.pos.x){
                    this.ctx.drawImage(this.img, 48*c, 0+2*48, 48,48, x, y, 48,48);
                } else if ( this.target.x < this.pos.x) {
                    this.ctx.drawImage(this.img, 48*c, 0+1*48, 48,48, x, y, 48,48);
                } else if (this.target.y < this.pos.y){
                    this.ctx.drawImage(this.img, 48*c, 0+3*48, 48,48,  x, y, 48,48);
                } else {
                    this.ctx.drawImage(this.img, 48*c, 0, 48,48, x, y, 48,48);
                }
            }
        }
          
      }
}

