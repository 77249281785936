import { socket } from "./app";
import { playSound } from "./audio";
import { GameMap } from "./game_map";
import { EnemyType } from "./guard";
import { MapObject, MapObjectTask, ObjectType } from "./map_object";
import { Player } from "./player";
import { launchPuzzle, launchPuzzleMeta } from "./puzzle";
import { launchMachinePuzzle } from "./puzzles/puzzle_colored_squares";

let fps_counter = 0;
let last_time = performance.now();
let drawDurationTotal = 0;

setInterval(() => {
  console.log(fps_counter);
  console.log(drawDurationTotal/fps_counter);
  drawDurationTotal = 0;
  fps_counter = 0;
}, 1000)

export class World {
    players: Map<number, Player>;
    gameMaps: Array<GameMap>;

    // Client attributes
    myId: number;
    canvas: HTMLCanvasElement;
    ctx: CanvasRenderingContext2D;

    constructor(myId: number, canvas: HTMLCanvasElement, ctx: CanvasRenderingContext2D){
        this.players = new Map();
        this.gameMaps = new Array();
        this.myId = myId; // myPlayerId more exactly
        this.canvas = canvas;
        this.ctx = ctx;
    }

    localPlayerRequestActivation(): boolean{
      for (const div of document.getElementsByClassName("puzzle")){
        return false;
      }
      const myPlayer = this.players.get(this.myId);
      if (myPlayer){
        console.log("request activation");

          let otherPlayer = this.getOtherPlayerNearMe(myPlayer);
          if (typeof otherPlayer !== "string"){
            socket.send(JSON.stringify({ name: "unfreeze", other_player_id: otherPlayer.id }));
            return true;
          }

          

          // Check if an object is near
          const object = this.isThereObjectNearMe(myPlayer);
          if (typeof object !== "undefined") {
            if (object.name == ObjectType.Tree){
              socket.send(JSON.stringify({ name: "activate" }));
              // launchPuzzle();
            }
            else if (object instanceof MapObjectTask){
              launchPuzzleMeta(object.puzzle);
            }
            else if (object.name == ObjectType.Machine){
              launchMachinePuzzle();
            } else {
              playSound("activate");
              socket.send(JSON.stringify({ name: "activate" }));
            }
            return true;
          }

          // Check if a wall is near
          const wallId = this.getWallNearMe(myPlayer);
          if (typeof wallId == "number"){
            console.log("Send: break_wall")
            socket.send(JSON.stringify({ name: "break_wall" }));
            return true;
          }
        }
        return false;
      }
          

    updateGameProgressDiv(gameProgressDiv: HTMLDivElement, mapIndex: number) {
      gameProgressDiv.innerHTML = "";
      gameProgressDiv.innerHTML += "Étage : " + (mapIndex+1) + "<br>";

      const myGameMap = this.gameMaps[mapIndex];
      const nbTasks = myGameMap.nbTasks();
      const nbDoneTasks = myGameMap.nbDoneTasks();
      gameProgressDiv.innerHTML += "Quetes : " + nbDoneTasks + "/" + nbTasks + "<br>";

      if (myGameMap.isTreasureOpen()){
        gameProgressDiv.innerHTML += "Trésor : OK";
      } else {
        gameProgressDiv.innerHTML += "Trésor : X";
      }
    }

    /**
     * Return the number of guards.
     */
    getNbEnemies(kind: EnemyType){
      if (this.gameMaps.length > 0 ){
        let count = 0;
        for ( const guard of this.gameMaps[0].guards){
          if (guard.enemyType == kind){
            count += 1;
          }
        }
        return count;
      } else {
        return 0;
      }
    }

    draw(){
      fps_counter += 1;
      const startTime = performance.now();

        this.ctx.beginPath();
        this.ctx.fillStyle = "rgb(0,0,0,0)";
        this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
        this.ctx.fill();

        const myPlayer = this.players.get(this.myId);
        if (myPlayer != undefined){
            // draw my game map
            const myGameMap = this.gameMaps[myPlayer.mapIndex];
            myGameMap.drawCases(myPlayer);
            myGameMap.drawGuardsPath(myPlayer);
            myGameMap.drawGuards(myPlayer);
            myGameMap.draw_v_walls(myPlayer);
            myGameMap.draw_h_walls_between(myPlayer,0, myGameMap.width * myGameMap.cases.length);
            myGameMap.drawObjects(myPlayer);

            // draw players
            myPlayer.draw_me();

            for (const player of this.players.values()){
                if (player.id != this.myId){
                    player.draw(myPlayer);
                }
            }
        }
      drawDurationTotal += performance.now() - startTime;
    }

    getWallNearMe(myPlayer: Player): number | string {
      console.log("getWallNearMe");
      for (let i = 0 ; i < this.gameMaps[myPlayer.mapIndex].walls.length; i ++){
        const wall = this.gameMaps[myPlayer.mapIndex].walls[i];
        const middle = wall[0].middle(wall[1]);
        if ( myPlayer.pos.distTo(middle) <= 20){
          console.log("return ", i);
          return i;
        }
      }
      return "undefined";
    }


    getOtherPlayerNearMe(myPlayer: Player): Player | string{
        for (const player of this.players.values()){
          if ( player.id != myPlayer.id && (player.pos.x - myPlayer.pos.x)**2 + (player.pos.y - myPlayer.pos.y)**2 <= 400 ){
            return player;
          }
        }
        return "none";
      }


  isThereObjectNearMe(myPlayer: Player): undefined | MapObject{
    const myGameMap = this.gameMaps[myPlayer.mapIndex];
    for ( const object of myGameMap.objects){
      if ( object.pos.distTo(myPlayer.pos) <= 20 ){
        return object;
      }
    }
    return undefined;
  }


}