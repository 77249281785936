{
  "name": "burger-bingo-client",
  "version": "1.0.20",
  "description": "",
  "main": "src/index.html",
  "scripts": {
    "dev": "parcel src/index.html --port 3000",
    "build": "parcel build src/index.html"
  },
  "repository": {
    "type": "git",
    "url": "git+https://github.com/lucas-test/burger-bingo-client.git"
  },
  "author": "",
  "license": "ISC",
  "bugs": {
    "url": "https://github.com/lucas-test/burger-bingo-client/issues"
  },
  "homepage": "https://github.com/lucas-test/burger-bingo-client#readme",
  "devDependencies": {
    "@parcel/resolver-glob": "^2.9.1",
    "parcel": "^2.9.1",
    "parcel-plugin-static-files-copy": "^2.6.0",
    "parcel-transformer-markdown": "^3.0.0",
    "typescript": "^5.0.4"
  },
  "dependencies": {
    "@types/marked": "^5.0.0",
    "2d-coord": "^1.0.6",
    "marked": "^5.0.3"
  },
  "targets": {
    "main": false
  }
}
