const _temp0 = require("../../img/puzzles/beetle.png");
const _temp1 = require("../../img/puzzles/leaf.png");
const _temp2 = require("../../img/puzzles/mazeDeco1.png");
const _temp3 = require("../../img/puzzles/mazeDeco2.png");
const _temp4 = require("../../img/puzzles/mazeDeco3.png");
const _temp5 = require("../../img/puzzles/mazeFloor.png");
const _temp6 = require("../../img/puzzles/mazeFloor2.png");
const _temp7 = require("../../img/puzzles/mazeFloor50x50.png");
const _temp8 = require("../../img/puzzles/mazeFontain.png");
const _temp9 = require("../../img/puzzles/mazeMap.png");
const _temp10 = require("../../img/puzzles/mazeWater.png");
const _temp11 = require("../../img/puzzles/niche.png");
const _temp12 = require("../../img/puzzles/niche2.png");
const _temp13 = require("../../img/puzzles/torch_off.png");
const _temp14 = require("../../img/puzzles/torch_on.png");
const _temp15 = require("../../img/puzzles/trash.png");
const _temp16 = require("../../img/puzzles/whale.png");
const _temp17 = require("../../img/puzzles/whale2.png");
module.exports = {
  "beetle": _temp0,
  "leaf": _temp1,
  "mazeDeco1": _temp2,
  "mazeDeco2": _temp3,
  "mazeDeco3": _temp4,
  "mazeFloor": _temp5,
  "mazeFloor2": _temp6,
  "mazeFloor50x50": _temp7,
  "mazeFontain": _temp8,
  "mazeMap": _temp9,
  "mazeWater": _temp10,
  "niche": _temp11,
  "niche2": _temp12,
  "torch_off": _temp13,
  "torch_on": _temp14,
  "trash": _temp15,
  "whale": _temp16,
  "whale2": _temp17
}