
// ----------------- 
// Colored Square Puzzle

import { socket } from "../app";

function checkColoredSquarePuzzle(i: number, colors: Array<string>): boolean {
    let nbRed = 0;
    let nbBlue = 0;
    for (const color of colors){
      if (color == "red"){
        nbRed ++;
      } else {
        nbBlue ++;
      }
    }
    if (nbRed == 5){
      return colors[i] == "blue";
    } 
    else if (nbRed == 3){
      let found = 0;
      for (let j = 0 ; j < 9 ; j ++){
        if (colors[j] == "red"){
          found ++;
          if (found == 2){
            return j == i;
          }
        }
      }
      return true;
    } 
    else if (nbBlue %2 == 0){
       let count = 0;
       for (let j = 6; j < 9 ; j ++){
        if (colors[j] == "red"){
          count ++;
        }
       }
       if ( count == 0){
        return i == 4;
       } else if (count == 1){
        return colors[i] == "blue";
       } else {
        return i == 0;
       }
    } else if(nbRed == 0){
      return i == 4;
    }else {
      return colors[i] == "red";
    }
    
  }
  
  export function launchMachinePuzzle(){
    const div = document.createElement("div");
    document.body.appendChild(div);
    div.classList.add("puzzle");
    div.classList.add("coloredSquarePuzzle")
  
    const colors = new Array();
  
    for (let i = 0 ; i < 9 ; i +=1 ){
      const square = document.createElement("div");
      div.appendChild(square);
      square.id = "coloredSquarePuzzle_" + i;
      square.classList.add("puzzle-item-square");
      if (Math.random() < 0.5){
        square.classList.add("square_blue");
        colors.push("blue");
      } else {
        square.classList.add("square_red");
        colors.push("red");
      }
  
      square.onclick = function () {
        if (checkColoredSquarePuzzle(i,colors)){
          socket.send(JSON.stringify({ name: "puzzle_achieve" }));
          div.classList.add("achieved");
        }else {
          socket.send(JSON.stringify({ name: "puzzle_fail" }));
          div.classList.add("failed");
        }
        setTimeout(() => div.remove(), 1000);
      }
    }
  }