import { Case, CaseCamera, CaseExplode, CaseType, fromRawCase } from "./case";
import { EnemyType, Guard } from "./guard";
import { images, tryDrawCenter, try_draw } from "./images_import";
import { fromRawObject, MapObject, MapObjectTask, MapObjectTeleporter, ObjectType } from "./map_object";
import { Player } from "./player";
import ENV from './.env.json';
import { Coord } from "2d-coord";


export class GameMapEvent {
    launch_instant: number; // in millis
    duration: number; // in millis
}

export class EventExplosion extends GameMapEvent{
    case_coord: Coord;
}

export class EventStopSpeedPotion extends GameMapEvent{
    player_id: number;
}




export class GameMap{
    grid_size: number;
    width: number;
    cases: Array<Array<Case>>;
    walls: Array<Array<Coord>>;
    guards: Array<Guard>;
    objects: Array<MapObject>;
    mapIndex: number;
    planned_events: Array<GameMapEvent>;

    // Client
    backgoundCanvas: HTMLCanvasElement;
    canvas: HTMLCanvasElement;
    ctx: CanvasRenderingContext2D;

    constructor(width: number, mapIndex: number, canvas: HTMLCanvasElement, ctx: CanvasRenderingContext2D){
        this.grid_size = 0;
        this.width = width;
        this.walls = new Array();
        this.walls = new Array();
        this.guards = new Array();
        this.objects = new Array();
        this.mapIndex = mapIndex;
        this.planned_events = new Array();
        this.backgoundCanvas = document.createElement("canvas");
        // document.body.appendChild(this.backgoundCanvas);
        this.canvas = canvas;
        this.ctx = ctx;
    }

    static fromRawGameMap(rgm, canvas: HTMLCanvasElement, ctx: CanvasRenderingContext2D): GameMap{
        const new_map = new GameMap (rgm.width, parseInt(rgm.map_index), canvas, ctx) ; 
        const n = rgm.cases.length;
        new_map.grid_size = n;
        new_map.cases = new Array();
        for ( let i = 0 ; i < n ; i ++ ){
            new_map.cases.push([]);
            for (let j = 0 ; j < n ; j ++ ){
            const newCase = fromRawCase(rgm.cases[i][j]);
            new_map.cases[i].push(newCase);
            }
        }
        new_map.setupBackground();
        for (const rawWall of rgm.walls){
            const wall = new Array();
            wall.push(new Coord(parseInt(rawWall.ext1.x), parseInt(rawWall.ext1.y)));
            wall.push(new Coord(parseInt(rawWall.ext2.x), parseInt(rawWall.ext2.y)));
            new_map.walls.push(wall)
        }
        for (const guard of rgm.guards){
            const pos = new Coord(guard.pos.x, guard.pos.y);
            const target = new Coord(guard.target.x, guard.target.y);
            const new_guard = new Guard(pos, target, guard.speed, guard.case_coord, guard.path, guard.active, guard.enemy_type as EnemyType, canvas,ctx);
            new_map.guards.push(new_guard);
        }
        for (const raw_object of rgm.objects){
            const new_object = fromRawObject(raw_object);
            if (new_object){
            new_map.objects.push(new_object);
            }
        }
        return new_map;
    }

    /**
     * Returns the number of tasks in this map.
     * This is the number of objects of type Task.
     */
    nbTasks(): number {
        let nb = 0;
        for (const object of this.objects){
            if (object.name == ObjectType.Task){
                nb ++;
            }
        }
        return nb;
    }

    /**
     * Returns the number of done tasks in this map.
     */
    nbDoneTasks(): number {
        let nb = 0;
        for (const object of this.objects){
            if (object.name == ObjectType.Task){
                const task = object as MapObjectTask;
                if (task.done){
                    nb ++;
                }
            }
        }
        return nb;
    }

    /**
     * Returns true if objects contains a TreasureOpen.
     * /!\ should be refacto when TreasureObject will be created
     * /!\ if there are multiple chests, its false
     */
    isTreasureOpen(): boolean {
        for (const object of this.objects){
            if (object.name == ObjectType.TreasureOpen){
                return true;
            }
        }
        return false;
    }



    drawGuards(myPlayer: Player){
        for (const guard of this.guards){
            guard.draw(myPlayer);
        }
    }

    /**
     * Draw the 0-layer on a special canvas.
     * This canvas should be only draw a little number of times.
     */
    setupBackground(){
        console.log("setup background");
        
        const width = this.width;
        const n = this.cases.length;
        this.backgoundCanvas.width = n*width;
        this.backgoundCanvas.height = n*width;

        const ctx = this.backgoundCanvas.getContext('2d');
        if (!ctx) return;
        console.log("hey");


        for (let i = 0 ; i < n ; i ++){
            for (let j = 0 ; j < n; j ++ ){
                const x = Math.floor(i*width);
                const y = Math.floor(j*width);
                if (this.cases[i][j].case_type == CaseType.Explode){
                    const c = this.cases[i][j] as CaseExplode;
                    if (c.state == 0){
                        try_draw(ctx, "ExplodeGreen", x, y);
                    } else if (c.state == 1){
                        try_draw(ctx, "ExplodeOrange", x, y);
                    } else {
                        try_draw(ctx, "ExplodeRed", x, y);
                    }
                } else if (this.cases[i][j].case_type == CaseType.Camera){
                    const c = this.cases[i][j] as CaseCamera;
                    if (c.active){
                        try_draw(ctx, CaseType.Camera, x, y);
                    } else {
                        try_draw(ctx, "CameraOff", x, y);
                    }
                }  else {
                    try_draw(ctx, this.cases[i][j].case_type, x, y);
                }
            }
        }
    }


    drawCases(myPlayer: Player){
        const width = this.width;
        const n = this.cases.length;
        // this.ctx.drawImage(this.backgoundCanvas, this.canvas.width/2-myPlayer.pos.x,  this.canvas.height/2 - myPlayer.pos.y);

        // this.ctx.drawImage(this.backgoundCanvas, 
        //     myPlayer.pos.x - this.canvas.width/2,  myPlayer.pos.y - this.canvas.height/2,
        //     this.canvas.width, this.canvas.height,
        //     0, 0,
        //     this.canvas.width, this.canvas.height);

        for (let i = 0 ; i < n ; i ++){
        for (let j = 0 ; j < n; j ++ ){
            
            const x = Math.floor(i*width + this.canvas.width/2 - myPlayer.pos.x);
            const y = Math.floor(j*width + this.canvas.height/2 - myPlayer.pos.y);

            

            if (this.cases[i][j].case_type == CaseType.Explode){
                const c = this.cases[i][j] as CaseExplode;
                if (c.state == 0){
                    try_draw(this.ctx, "ExplodeGreen", x, y);
                } else if (c.state == 1){
                    try_draw(this.ctx, "ExplodeOrange", x, y);
                } else {
                    try_draw(this.ctx, "ExplodeRed", x, y);
                }
            } else if (this.cases[i][j].case_type == CaseType.Camera){
                const c = this.cases[i][j] as CaseCamera;
                if (c.active){
                    try_draw(this.ctx, CaseType.Camera, x, y);
                } else {
                    try_draw(this.ctx, "CameraOff", x, y);
                }
            }  else {
                try_draw(this.ctx, this.cases[i][j].case_type, x, y);
            }
    
            for (const guard of this.guards){
                if ( guard.caseCoord.x == i && guard.caseCoord.y == j){
                const x = Math.floor(i*width + this.canvas.width/2 - myPlayer.pos.x);
                const y = Math.floor(j*width + this.canvas.height/2 - myPlayer.pos.y);
                try_draw(this.ctx, "guard_case", x, y);
                break;
                }
            }
    
            if (myPlayer.case_coord.x == i && myPlayer.case_coord.y == j){
                const x = Math.floor(i*width + this.canvas.width/2 - myPlayer.pos.x);
                const y = Math.floor(j*width + this.canvas.height/2 - myPlayer.pos.y);
                try_draw(this.ctx, "player_case", x, y);
            }
    
            if (this.cases[i][j].discovered == false){
            // const x = Math.floor(i*width + this.canvas.width/2 - myPlayer.pos.x);
            // const y = Math.floor(j*width + this.canvas.height/2 - myPlayer.pos.y);
            const x = Math.floor(i*width + width/2 + this.canvas.width/2 - myPlayer.pos.x);
            const y = Math.floor(j*width + width/2 + this.canvas.height/2 - myPlayer.pos.y);
            // try_draw(this.ctx, "undiscovered", x, y);
            tryDrawCenter(this.ctx, "undiscovered", x, y);
            }
            
            
        }
        }
    }


    /// Draw horizontal walls only if y-coordinate of the wall is y1 <= y < y2
    draw_h_walls_between(myPlayer: Player, y1, y2){
        const wall_img = images.get("wall");
        if (wall_img){
            for (const wall of this.walls){
                const w0x = Math.floor(wall[0].x);
                const w0y = Math.floor(wall[0].y);
                const w1x = Math.floor(wall[1].x);
                const w1y = Math.floor(wall[1].y);
            
                if (wall[0].y == wall[1].y  && y1 <= wall[0].y &&  wall[0].y < y2 ){

                  if ( (myPlayer.hasObject(ObjectType.Hammer) || myPlayer.can_break_wall) && myPlayer.pos.distTo(wall[0].middle(wall[1])) <= 20){
                    try_draw(this.ctx, "selectable", w0x + this.canvas.width/2 +16- myPlayer.pos.x  , 10 + w0y-30  + this.canvas.height/2 - myPlayer.pos.y)
                  }
                  
                  this.ctx.drawImage(wall_img, w0x + this.canvas.width/2 - myPlayer.pos.x , w0y-30  + this.canvas.height/2 - myPlayer.pos.y);
                  this.ctx.drawImage(wall_img, w0x + this.canvas.width/2 - myPlayer.pos.x + 50 , w0y-30  + this.canvas.height/2 - myPlayer.pos.y);
                  this.ctx.beginPath();
                  this.ctx.rect(w0x  + this.canvas.width/2- myPlayer.pos.x, w0y -40  + this.canvas.height/2- myPlayer.pos.y, 100, 10);
                  this.ctx.fillStyle = "#7d502d";
                  this.ctx.fill();
                }
              }
        }
        
      }



 draw_v_walls(myPlayer: Player){
    const img_v_wall = images.get("v_wall");
    if (!img_v_wall){
        return;
    }
    for (const wall of this.walls){
      const w0x = Math.floor(wall[0].x);
      const w0y = Math.floor(wall[0].y);
      const w1x = Math.floor(wall[1].x);
      const w1y = Math.floor(wall[1].y);
  
      if (!(wall[0].y == wall[1].y) ){

        if ((myPlayer.hasObject(ObjectType.Hammer) || myPlayer.can_break_wall) && myPlayer.pos.distTo(wall[0].middle(wall[1])) <= 20){
            try_draw(this.ctx, "selectable", w0x -32 + this.canvas.width/2 - myPlayer.pos.x  , 50+ w0y-30  + this.canvas.height/2 - myPlayer.pos.y)
          }
        
        this.ctx.beginPath();
        this.ctx.moveTo(w0x + this.canvas.width/2- myPlayer.pos.x, w0y + this.canvas.height/2- myPlayer.pos.y -30);
        this.ctx.lineTo(wall[1].x + this.canvas.width/2- myPlayer.pos.x, wall[1].y + this.canvas.height/2- myPlayer.pos.y -30);
        this.ctx.lineWidth = 10;
        this.ctx.strokeStyle = "#7d502d";
        this.ctx.stroke();
  
        this.ctx.drawImage(img_v_wall, wall[1].x + this.canvas.width/2- myPlayer.pos.x-5, wall[1].y + this.canvas.height/2- myPlayer.pos.y -30 );
        // ctx.beginPath();
        // ctx.moveTo(wall[1].x + canvas.width/2- my_data.pos.x, wall[1].y + canvas.height/2- my_data.pos.y -30);
        // ctx.lineTo(wall[1].x + canvas.width/2- my_data.pos.x, wall[1].y + canvas.height/2- my_data.pos.y);
        // ctx.lineWidth = 10;
        // ctx.strokeStyle = "rgb(140,140,140)";
        // ctx.stroke();
      }
    }
  }
  


    drawGuardsPath(myPlayer: Player){
        this.ctx.strokeStyle = "brown";
        this.ctx.lineWidth = 5;
        this.ctx.lineCap = "round";

        for (const guard of this.guards){
            if (guard.enemyType == EnemyType.Guard){
                this.ctx.beginPath();
                this.ctx.moveTo(guard.pos.x + this.canvas.width/2 - myPlayer.pos.x, guard.pos.y + this.canvas.height/2 - myPlayer.pos.y )
                this.ctx.lineTo(guard.target.x + this.canvas.width/2 - myPlayer.pos.x , guard.target.y + this.canvas.height/2 - myPlayer.pos.y)
                for ( let i =  guard.path.length-1 ; i >= 0 ; i --){
                    const point = guard.path[i];
                    this.ctx.lineTo(point.x*100+50 + this.canvas.width/2 - myPlayer.pos.x , point.y*100+50 + this.canvas.height/2 - myPlayer.pos.y)
                }
                this.ctx.stroke();
            }
        }
    }



    drawObjects(myPlayer: Player){
        const image_selectable = images.get("selectable");
        if (!image_selectable){
            return;
        }

        for ( const object of this.objects){
            const i = Math.floor(object.pos.x/this.width);
            const j = Math.floor(object.pos.y/this.width);
            if (this.cases[i][j].discovered == false) {
                continue;
            }
        
            const x = object.pos.x;
            const y = object.pos.y;
        
            // draw selectable
            if ( (object.pos.x - myPlayer.pos.x)**2 + (object.pos.y - myPlayer.pos.y)**2 <= 400 ){
                const pos2x = Math.floor(x + this.canvas.width/2 - myPlayer.pos.x - image_selectable.width/2);
                const pos2y = Math.floor(10 + y + this.canvas.height/2 - myPlayer.pos.y - image_selectable.height/2);
                this.ctx.drawImage(image_selectable, pos2x, pos2y);
            }
            
            const image = images.get(object.imgSrc);
            if (image){
                const posx = Math.floor(x + this.canvas.width/2 - myPlayer.pos.x - image.width/2);
                const posy = Math.floor(y + this.canvas.height/2 - myPlayer.pos.y - image.height/2);
                this.ctx.drawImage(image, posx, posy);
            }
            
        
            if (ENV.devmode){
                // draw circle at center of object
                const posx =  Math.floor(x + this.canvas.width/2 - myPlayer.pos.x );
                const posy = Math.floor(y + this.canvas.height/2 - myPlayer.pos.y );
                this.ctx.fillStyle = "blue";
                this.ctx.beginPath();
                this.ctx.arc(posx, posy, 4, 0, 2 * Math.PI);
                this.ctx.fill();
            }
      
        }
    }

}