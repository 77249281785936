import { Coord } from "2d-coord";
import { socket } from "../app";
import ENV from '../.env.json';

let isPuzzleOver = false;

function achievePuzzle(div: HTMLDivElement){
    if (isPuzzleOver == false){
        isPuzzleOver = true;
        div.classList.add("achieved");
        setTimeout(() => div.remove(), 1000);
        socket.send(JSON.stringify({ name: "puzzle_achieve" }));
    }
}

function failPuzzle(div: HTMLDivElement){
    if ( isPuzzleOver == false){
        isPuzzleOver = true;
        div.classList.add("failed");
        setTimeout(() => {div.remove()}, 1000);
        socket.send(JSON.stringify({ name: "puzzle_fail" }));
    }
}



const n = 8;
let pos = new Coord(Math.floor(Math.random()*n), Math.floor(Math.random()*n));


function closeRight(grid: Array<Array<MazeSquare>>, x: number, y: number ){
    grid[y][x].right = false;
    if (ENV.devmode){
        // grid[y][x].div.style.borderRight = "solid 1px white";
    }
    grid[y][x+1].left = false;
}

function closeBottom(grid: Array<Array<MazeSquare>>, x: number, y: number ){
    grid[y][x].bottom = false;
    grid[y+1][x].top = false;
    if (ENV.devmode){
        // grid[y][x].div.style.borderBottom = "solid 1px white";
    }
}


function findPath(
    grid: Array<Array<MazeSquare>>,
    start: number,
    target: number,
): number | undefined {

    let queue: Array<number> = [start];
    let visited: Map<number, number> = new Map();
    visited.set(start, 0);

    const corresp: Map<number, Coord> = new Map();
    for (let i = 0 ; i < grid.length ; i ++){
        for (let j = 0 ; j < grid.length; j ++){
            corresp.set(grid[i][j].id, new Coord(j,i));
        }
    }

    while (queue.length > 0) {
        const toTreat = queue.shift();
        if( typeof toTreat === "undefined"){
            break;
        }

        const pos = corresp.get(toTreat);
        if (typeof pos === "undefined"){
            return undefined; // bug
        }

        if (grid[pos.y][pos.x].state == MazeSquareState.Forbidden){
            continue;
        }


        const d = visited.get(toTreat);
        if ( typeof d === "undefined"){
            return undefined; // bug
        }



        if ( toTreat == target ) {
            return d;
        }


        if (pos.y-1 >= 0 && grid[pos.y][pos.x].top   )
        {
            const adjId = grid[pos.y-1][pos.x].id;
            if (!visited.has(adjId)){
                queue.push(adjId);
                visited.set(adjId, d+1);
            }
        }

        if (pos.y+1 < grid.length && grid[pos.y][pos.x].bottom   )
        {
            const adjId = grid[pos.y+1][pos.x].id;
            if (!visited.has(adjId)){
                queue.push(adjId);
                visited.set(adjId, d+1);
            }
        }

        if (pos.x-1 >= 0 && grid[pos.y][pos.x].left   )
        {
            const adjId = grid[pos.y][pos.x-1].id;
            if (!visited.has(adjId)){
                queue.push(adjId);
                visited.set(adjId, d+1);
            }
        }

        if (pos.x+1 < grid.length && grid[pos.y][pos.x].right   )
        {
            const adjId = grid[pos.y][pos.x+1].id;
            if (!visited.has(adjId)){
                queue.push(adjId);
                visited.set(adjId, d+1);
            }
        }

            
    }
    return visited.get(target);
}

enum MazeSquareState {
    Clear,
    Forbidden,
    Target
}


class MazeSquare {
    div: HTMLDivElement;
    id: number;
    left: boolean;
    right: boolean;
    top: boolean;
    bottom: boolean;
    state: MazeSquareState;

    constructor(div: HTMLDivElement, id: number){
        this.div = div;
        this.id = id;
        this.left = true;
        this.right = true;
        this.top = true;
        this.bottom = true;
        this.state = MazeSquareState.Clear;
    }
}


function updateGridColors(grid: Array<Array<MazeSquare>>  ){
    const n = grid.length;
    for ( let i = 0 ; i < n ; i ++){
        for (let j = 0 ; j < n ; j ++){
            grid[i][j].div.classList.remove("activeSquare", "targetSquare", "forbiddenSquare");
            if (grid[i][j].state == MazeSquareState.Target){
                grid[i][j].div.classList.add("targetSquare");
            }
            if (i == pos.y && j == pos.x) {
                grid[i][j].div.classList.add("activeSquare");
            }
            if (grid[i][j].state == MazeSquareState.Forbidden){
                grid[i][j].div.classList.add("forbiddenSquare");
            }
        }
    }
}


let div: HTMLDivElement | undefined = undefined;
let grid: Array<Array<MazeSquare>> = new Array();

window.addEventListener("keydown", (e) => {
    if (typeof div !== "undefined"){
        if (e.key == "z" && pos.y > 0 && grid[pos.y][pos.x].top ){
            pos.y --;
        } else if (e.key == "s" && pos.y+1 < n && grid[pos.y][pos.x].bottom){
            pos.y ++;
        } else if (e.key == "q" && pos.x > 0 && grid[pos.y][pos.x].left ){
            pos.x --;
        } else if (e.key == "d" && pos.x+1 < n && grid[pos.y][pos.x].right){
            pos.x ++;
        } 

        if (e.key == "ArrowUp" && pos.y > 0 && grid[pos.y][pos.x].top ){
            pos.y --;
        } else if (e.key == "ArrowDown" && pos.y+1 < n && grid[pos.y][pos.x].bottom){
            pos.y ++;
        } else if (e.key == "ArrowLeft" && pos.x > 0 && grid[pos.y][pos.x].left ){
            pos.x --;
        } else if (e.key == "ArrowRight" && pos.x+1 < n && grid[pos.y][pos.x].right){
            pos.x ++;
        } 
    
        if (grid[pos.y][pos.x].state == MazeSquareState.Target){
            achievePuzzle(div);
        } else if (grid[pos.y][pos.x].state == MazeSquareState.Forbidden){
            failPuzzle(div);
        }
        updateGridColors(grid);
    }
});



export function launchPuzzleMaze(){
    div = document.createElement("div");
    document.body.appendChild(div);
    div.classList.add("puzzle");
    // div.classList.add("gridPuzzle");

    const mazeDiv = document.createElement("div");
    mazeDiv.classList.add("mazeFloor")
    div.appendChild(mazeDiv);

    isPuzzleOver = false;

    // div.style.gridTemplateColumns = "repeat(" + n + ", 51px)";
    // div.style.gridTemplateRows = "repeat(" + n + ", 51px)";
    mazeDiv.style.gridTemplateColumns = "repeat(" + n + ", 50px)";
    mazeDiv.style.gridTemplateRows = "repeat(" + n + ", 50px)";
    mazeDiv.style.width = (n*50) + "px";

    grid = new Array();
    let id = 0;
    for (let i = 0 ; i < n ; i ++){
        grid.push( new Array());
        for (let j = 0 ; j < n ; j ++){
            const square = document.createElement("div");
            mazeDiv.appendChild(square);
            square.classList.add("mazeSquare");
            const mazeSquare = new MazeSquare(square, id);
            id ++;
            grid[i].push(mazeSquare);

            square.onmouseenter = (e) => {
                console.log(j,i);
            }

        }
    }


    for (let i = 0 ; i < 6 ; i ++){
        const deco = document.createElement("div");
        const decoType = Math.floor(Math.random()*3)+1;
        deco.classList.add("mazeDeco", "mazeDeco" + decoType);
        deco.style.top = (40+Math.floor(Math.random()*n)*50) + "px";
        deco.style.left = (40+Math.floor(Math.random()*n)*50) + "px";
        mazeDiv.appendChild(deco);
    }



    

    closeRight(grid,0,1);
    closeRight(grid,0,2);
    closeRight(grid,0,4);
    // closeRight(grid,1,1);    
    closeRight(grid,6,4);
    closeRight(grid,5,0);
    closeRight(grid,1,0);
    // closeRight(grid,3,1);
    closeRight(grid,0,5);
    closeRight(grid,3,2);
    closeRight(grid,6,1);
    closeRight(grid,3,3);
    // closeRight(grid,3,6);
    closeRight(grid,5,6);
    closeRight(grid,6,2);
    closeRight(grid,4,1);
    closeRight(grid,4,2);
    closeRight(grid,4,3);

    closeBottom(grid,1,2);
    closeBottom(grid,3,2);
    closeBottom(grid,4,4);
    closeBottom(grid,7,1);
    // closeBottom(grid,1,2);
    closeBottom(grid,6,6);
    closeBottom(grid,6,5);
    closeBottom(grid,3,3);
    closeBottom(grid,4,3);
    closeBottom(grid,5,3);
    // closeBottom(grid,3,6);
    closeBottom(grid,2,6);
    closeBottom(grid,2,5);
    // closeBottom(grid,1,5);
    closeBottom(grid,2,4);
    closeRight(grid,1,4);
    closeBottom(grid,2,3);
    closeBottom(grid,0,4);
    closeRight(grid,0,6);
    closeRight(grid,3,5);
    // closeRight(grid,1,3);
    closeBottom(grid,2,1);
    closeBottom(grid,3,0);
    closeRight(grid,5,1);
    // closeRight(grid,5,2);
    closeBottom(grid,6,3);
    closeBottom(grid,7,5);
    // closeRight(grid,5,5);
    closeBottom(grid,5,5);
    closeBottom(grid,4,6);
    closeRight(grid,2,7);


    grid[1][2].state = MazeSquareState.Forbidden;
    grid[2][0].state = MazeSquareState.Forbidden;
    grid[6][3].state = MazeSquareState.Forbidden;
    grid[7][2].state = MazeSquareState.Forbidden;
    grid[4][6].state = MazeSquareState.Forbidden;
    grid[4][2].state = MazeSquareState.Forbidden;
    grid[3][5].state = MazeSquareState.Forbidden;

    while (true){
        pos = new Coord(Math.floor(Math.random()*n), Math.floor(Math.random()*n));
        if (grid[pos.y][pos.x].state == MazeSquareState.Forbidden){
            continue;
        }
        const targetPos = new Coord(Math.floor(Math.random()*n), Math.floor(Math.random()*n));
        if ( grid[targetPos.y][targetPos.x].state == MazeSquareState.Forbidden){
            continue;
        }
        grid[targetPos.y][targetPos.x].state = MazeSquareState.Target;
        
        const d = findPath(grid, grid[pos.y][pos.x].id, grid[targetPos.y][targetPos.x].id);
        if ( typeof d !== "undefined" && d > 6){
            break;
        }

        grid[targetPos.y][targetPos.x].state = MazeSquareState.Clear;

    }

    updateGridColors(grid);


  
}