import { socket } from "./app";
import { info, ObjectType } from "./map_object";
import { Player } from "./player";
import { launchBookPopUp } from "./puzzle";

export function initBackpackDiv(){
    const backpack = document.createElement("div");
    backpack.id = "backpack";
    backpack.replaceChildren();

    // Create 4 slots (called here space).
    for ( let i = 0 ; i < 4 ; i ++){
        const backpackSpace = document.createElement("div");
        
        backpackSpace.classList.add("backpackSpace");
        backpack.appendChild(backpackSpace);

        const backpackSpaceContent = document.createElement("div");
        backpackSpaceContent.id = "backpackSpaceContent" + i;
        backpackSpaceContent.classList.add("backpackSpaceContent");
        backpackSpace.appendChild(backpackSpaceContent);

        // ProgressBar over the image
        const progressBar = document.createElement("div");
        progressBar.id = "backpackProgressBar" + i;
        progressBar.classList.add("backpackSpaceProgressBar");
        progressBar.style.width = 0 + "%"; 
        backpackSpaceContent.appendChild(progressBar);

        // InfoBox
        const infobox = document.createElement("div");
        infobox.id = "backpackInfobox" + i;
        infobox.innerHTML = "";
        infobox.classList.add("infobox");

        backpackSpaceContent.addEventListener("mouseover", () => {
            infobox.style.display = "block";
        });

        backpackSpaceContent.addEventListener("mouseout", () => {
            infobox.style.display = "none";
        });

        backpackSpace.appendChild(infobox);
    }

    document.body.appendChild(backpack);
}

function hideProgressBar(index: number) {
    const progressBar = document.getElementById("backpackProgressBar" + index);
    if (progressBar){
        progressBar.style.visibility = "hidden";
    }
}


function showProgressBar(index: number) {
    const progressBar = document.getElementById("backpackProgressBar" + index);
    if (progressBar){
        progressBar.style.visibility = "visible";
    }
}

function setProgressBar(index: number, ratio: number){
    const progressBar = document.getElementById("backpackProgressBar" + index);
    if (progressBar){
        progressBar.style.width = ratio.toString() + "%"; 
    }
}


export function updateBackpackDiv(myPlayer: Player){
    console.log("updateBackpackDiv");
    for (let i = 0 ; i < 4 ; i ++){
        const backpackSpaceContent = document.getElementById("backpackSpaceContent" + i);
        if (backpackSpaceContent){
            backpackSpaceContent.classList.remove("backpackHammer", "backpackSpaceContentBague", "backpackSpaceContentSpeedPotion", "backpackSpaceContentKey", "backpackSpaceContentUnfreezePotion", "backpackSpaceContentBook")
            backpackSpaceContent.onclick = function () { }
        }
        const infobox = document.getElementById("backpackInfobox" + i);
        if (infobox){
            infobox.innerHTML = "";
        }
    }
    for (const [index, object] of myPlayer.backpack.entries()){
        const backpackSpaceContent = document.getElementById("backpackSpaceContent" + index);
        if (backpackSpaceContent){

            const ratio = object.getCoolDownRatio();
            if (ratio >= 100){
                // try to stop the interval
                if (typeof object.progressInterval === "number"){
                    hideProgressBar(index);
                    clearInterval(object.progressInterval);
                    object.progressInterval = null;
                }
            }
            else {
                console.log("start a new interval");
                setProgressBar(index, 0);
                showProgressBar(index);
                if (typeof object.progressInterval !== "number"){
                    object.progressInterval = setInterval(() => {
                        const ratio = object.getCoolDownRatio();
                        if (ratio >= 100){
                            if (typeof object.progressInterval === "number"){
                                hideProgressBar(index);
                                clearInterval(object.progressInterval);
                                object.progressInterval = null;
                            }
                        }
                        setProgressBar(index, ratio);
                    }, 100);
                }
            }
            
            if (object.name == ObjectType.Bague){
                backpackSpaceContent.classList.add("backpackSpaceContentBague");
                backpackSpaceContent.onclick = function () {  
                    socket.send(JSON.stringify({ name: "use_object", id: index}));
                 }
            } else if (object.name == ObjectType.SpeedPotion){
                backpackSpaceContent.classList.add("backpackSpaceContentSpeedPotion");
                backpackSpaceContent.onclick = function () {  socket.send(JSON.stringify({ name: "use_object", id: index})) }
            } else if (object.name == ObjectType.Key){
                backpackSpaceContent.classList.add("backpackSpaceContentKey");
            } else if (object.name == ObjectType.UnfreezePotion){
                backpackSpaceContent.classList.add("backpackSpaceContentUnfreezePotion");
                backpackSpaceContent.onclick = function () { socket.send(JSON.stringify({ name: "use_object", id: index})) }
            } else if (object.name == ObjectType.Book){
                backpackSpaceContent.classList.add("backpackSpaceContentBook");
                backpackSpaceContent.onclick = function () {  launchBookPopUp("lol") }
            } else if (object.name == ObjectType.Hammer){
                backpackSpaceContent.classList.add("backpackHammer");
                backpackSpaceContent.onclick = function () { socket.send(JSON.stringify({ name: "use_object", id: index})) }
            }

            const infobox = document.getElementById("backpackInfobox" + index);
            if (infobox){
                infobox.innerHTML = info(object.name);
            }

            
            
            
        } else {
            console.log("bug", index);
        }
    }
}

