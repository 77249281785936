import { Coord } from "2d-coord";
import { socket } from "../app";
import { images } from "../images_import";
import { DragContainer } from "./drag_container";

// Parameters

const nbLeaves = 8;

// Puzzle

let isPuzzleOver = false;

export function launchPuzzleLeaves(){
    isPuzzleOver = false;

    const div = document.createElement("div");
    document.body.appendChild(div);
    div.classList.add("puzzle");

    const beetle = document.createElement("img");
    const imgBeetle = images.get("PuzzleBeetle");
    if (imgBeetle){
        beetle.src = imgBeetle.src;
    }
    div.appendChild(beetle);
    beetle.classList.add("beetle");
    const x = 20 + Math.floor(Math.random()*400);
    const y = 20 + Math.floor(Math.random()*400);
    beetle.style.top = y.toString();
    beetle.style.left = x.toString();

    beetle.onclick = (e) => {
        if (isPuzzleOver == false){
            isPuzzleOver = true;
            div.classList.add("achieved");
            setTimeout(() => div.remove(), 1000);
            socket.send(JSON.stringify({ name: "puzzle_achieve" }));
        }
    }

    // Leaves
    const container = new DragContainer(div);
    for (let i = 0 ; i < nbLeaves ; i++){
        const x = Math.floor(Math.random()*400);
        const y = Math.floor(Math.random()*400);
        beetle.style.top = y.toString();
        beetle.style.left = x.toString();
        container.addImage("PuzzleLeaf", "leaf", new Coord(x,y));
        container.lastElt().div.style.transform = "rotate(" + Math.floor(Math.random()*360) + "deg)";
    }
}