import { mega_counter } from "./app";
import { fromRawObject, MapObject, ObjectType } from "./map_object";
import ENV from './.env.json';
import { images } from "./images_import";
import { Coord } from "2d-coord";


export enum Direction {
    Left,
    Right,
    Up,
    Down,
}

export enum PlayerType {
    Frodon = "Frodon",
    Gandalf = "Gandalf",
    Gollum = "Gollum",
    Sam = "Sam",
    Arwen = "Arwen",
}

export class Player {
    id: number;
    pos: Coord;
    speed: number;
    case_coord: Coord;
    is_moving: boolean;
    direction: Direction;
    dir_vect: Coord;
    is_mouse_moving: boolean;
    backpack: Array<MapObject>;
    is_sick: boolean;
    mapIndex: number;
    player_type: PlayerType;
    is_frozen: boolean;
    capacity_wall_breaker: boolean;
    can_break_wall: boolean;
    capacity_cool_down_start: number;
    capacity_cool_down_over: number;

    // Client attributes
    canvas: HTMLCanvasElement;
    ctx: CanvasRenderingContext2D;

    constructor(id: number, pos: Coord, speed: number, case_coord: Coord, direction: Direction, dir_vect: Coord, mapIndex: number, playerType: PlayerType, is_sick: boolean, is_frozen: boolean, capacity_wall_breaker: boolean, can_break_wall: boolean, capacity_cool_down_over: number, capacity_cool_down_start: number, canvas: HTMLCanvasElement, ctx: CanvasRenderingContext2D){
        this.id = id;
        this.pos = pos;
        this.speed = speed;
        this.case_coord = case_coord;
        this.direction = direction;
        this.dir_vect = dir_vect;
        this.is_mouse_moving = false;
        this.backpack = new Array();
        this.is_sick = is_sick;
        this.mapIndex = mapIndex;
        this.player_type = playerType;
        this.is_frozen = is_frozen;
        this.capacity_wall_breaker = capacity_wall_breaker;
        this.can_break_wall = can_break_wall;
        this.capacity_cool_down_start = capacity_cool_down_start;
        this.capacity_cool_down_over = capacity_cool_down_over;

        this.canvas = canvas;
        this.ctx = ctx;
    }

    /**
     *  Return true if the backpack contains the specified object type. 
     * */
    hasObject(type: ObjectType): boolean {
        for (const object of this.backpack.values()){
            if (object.name == type){
                return true;
            }
        }
        return false;
    }

    capacityCoolDownRatio(): number {
        if (this.can_break_wall){
            return 0;
        }
        const now = new Date();
        const nowMs = now.getTime();
        const duration = (this.capacity_cool_down_over - this.capacity_cool_down_start);
        const ratio = 100* (nowMs - this.capacity_cool_down_start) / duration;

        if (ratio < 0){
            return 0;
        } else if (ratio >= 100){
            return 100;
        } else {
            return ratio;  
        }
    }

    /**
     * Draw the player this.
     * myPlayer is the player from the client.
     * Coordinates are computed from myPlayer to center it.
     */
    draw(myPlayer: Player){
        if (this.mapIndex == myPlayer.mapIndex){
        const cx = this.pos.x + this.canvas.width/2 - myPlayer.pos.x
        const cy = this.pos.y + this.canvas.height/2 - myPlayer.pos.y
    
        const image = images.get(this.player_type);
        if ( image == undefined){
            return;
        }
    

        if (this.is_frozen){
            const img_frozen = images.get("frozen");
            if (img_frozen == undefined){
                return;
            }
            this.ctx.drawImage(img_frozen, cx-48/2-10, cy-48/2);
        }
    
        if (this.dir_vect !== undefined){
            let c = mega_counter %3;
            if ( !this.is_mouse_moving ){
            c = 1
            }
            if ( this.dir_vect.x > 0.5){
                this.ctx.drawImage(image, 48*c, 0+2*48, 48,48, cx - 48/2, cy- 48, 48,48);
            } else if ( this.dir_vect.x < -0.5) {
                this.ctx.drawImage(image, 48*c, 0+1*48, 48,48, cx - 48/2, cy- 48, 48,48);
            } else if (this.dir_vect.y < -0.5){
                this.ctx.drawImage(image, 48*c, 0+3*48, 48,48, cx - 48/2, cy- 48, 48,48);
            } else {
                this.ctx.drawImage(image, 48*c, 0, 48,48, cx - 48/2, cy- 48, 48,48);
            }
        } else {
            this.ctx.drawImage(image, 0, 0, 48,48, cx - 48/2, cy- 48, 48,48);
        }

        if ( this.is_sick){
            const imgSick = images.get("sick");
            if (imgSick == undefined){
                return;
            }
            this.ctx.drawImage(imgSick, cx-48/2-10, cy-48/2);
        }

        }

        
  
    }


    resetBackpackFromRaw(rawBackpack){
        this.backpack = new Array();
        for (const raw_object of rawBackpack){
            const newObject = fromRawObject(raw_object);
            if (newObject){
                this.backpack.push(newObject);
            }
        }
    }
    

     draw_me() {
        
        const image = images.get(this.player_type);
        if ( image == undefined){
            return;
        }

        if (this.is_frozen){
            const img_frozen = images.get("frozen");
            if (img_frozen == undefined){
                return;
            }
            this.ctx.drawImage(img_frozen, this.canvas.width/2 -48/2-10, this.canvas.height/2-48/2);
        }
        
        if (this.dir_vect !== undefined){
            let c = mega_counter %3;
            if ( !this.is_mouse_moving ){
                c = 1
            }
            if ( this.dir_vect.x > 0.5){
                this.ctx.drawImage(image, 48*c, 0+2*48, 48,48, this.canvas.width/2 - 48/2, this.canvas.height/2- 48, 48,48);
            } else if ( this.dir_vect.x < -0.5) {
                this.ctx.drawImage(image, 48*c, 0+1*48, 48,48, this.canvas.width/2 - 48/2, this.canvas.height/2- 48, 48,48);
            } else if (this.dir_vect.y < -0.5){
                this.ctx.drawImage(image, 48*c, 0+3*48, 48,48, this.canvas.width/2 - 48/2, this.canvas.height/2- 48, 48,48);
            } else {
                this.ctx.drawImage(image, 48*c, 0, 48,48, this.canvas.width/2 - 48/2, this.canvas.height/2- 48, 48,48);
            }
        } else {
            this.ctx.drawImage(image, 48, 0, 48,48, this.canvas.width/2 - 48/2, this.canvas.height/2- 48, 48,48);
        }

        if ( this.is_sick){
            const imgSick = images.get("sick");
            if (imgSick == undefined){
                return;
            }
            this.ctx.drawImage(imgSick, this.canvas.width/2 -48/2-10, this.canvas.height/2-60);
        }
    
        if (ENV.devmode){
            this.ctx.fillStyle = "red";
            this.ctx.beginPath();
            this.ctx.arc(this.canvas.width/2, this.canvas.height/2, 4, 0, 2 * Math.PI);
            this.ctx.fill();
        }
  }
}