export let images = new Map<string,HTMLImageElement>();

function load_image (key: string, path) {
    const image = new Image();
    image.src = path;
    images.set(key, image);
  }



import img_selectable from '../img/selectable.png';
load_image("selectable", img_selectable);
import img_frozen from '../img/frozen.png';
load_image("frozen", img_frozen);
import img_wall from '../img/wall.png';
load_image("wall", img_wall);
import img_v_wall from '../img/v_wall.png';
load_image("v_wall", img_v_wall);


// PlayerType
import img_frodon from '../img/players/Frodon.png';
load_image("Frodon", img_frodon);
import img_gandalf from '../img/players/Gandalf.png';
load_image("Gandalf", img_gandalf);
import img_gollum from '../img/players/Gollum.png';
load_image("Gollum", img_gollum);
import img_sam from '../img/players/Sam.png';
load_image("Sam", img_sam);
import img_arwen from '../img/players/Arwen.png';
load_image("Arwen", img_arwen);

import imgSick from '../img/players/sick.png';
load_image("sick", imgSick);

import img_frodonIcon from '../img/players/FrodonIcon.png';
load_image("FrodonIcon", img_frodonIcon);
import img_gandalfIcon from '../img/players/GandalfIcon.png';
load_image("GandalfIcon", img_gandalfIcon);
import img_gollumIcon from '../img/players/GollumIcon.png';
load_image("GollumIcon", img_gollumIcon);
import img_samIcon from '../img/players/SamIcon.png';
load_image("SamIcon", img_samIcon);
import img_arwenIcon from '../img/players/ArwenIcon.png';
load_image("ArwenIcon", img_arwenIcon);


// CaseType
import imgSpawn from '../img/case_types/spawn.png';
load_image("Spawn", imgSpawn);
import img_undiscovered from '../img/case_types/undiscovered.png';
load_image("undiscovered", img_undiscovered);
import imgGuardCase from '../img/case_types/guard_case.png';
load_image("guard_case", imgGuardCase);
import imgPlayerCase from '../img/case_types/player_case.png';
load_image("player_case", imgPlayerCase);
import img_normal from '../img/case_types/normal.png';
load_image("Normal", img_normal);
import imgLowSpeed from '../img/case_types/low_speed.png';
load_image("LowSpeed", imgLowSpeed);

import imgCamera from '../img/case_types/camera.png'
load_image("Camera", imgCamera);
import imgCameraOff from '../img/case_types/camera_off.png'
load_image("CameraOff", imgCameraOff);

import img_stop from '../img/case_types/stop.png';
load_image("Stop", img_stop);
import img_explode from '../img/case_types/explode.png'
load_image("Explode", img_explode);

import imgExplodeGreen from '../img/case_types/explode_green.png'
load_image("ExplodeGreen", imgExplodeGreen);
import imgExplodeOrange from '../img/case_types/explode_orange.png'
load_image("ExplodeOrange", imgExplodeOrange);
import imgExplodeRed from '../img/case_types/explode_red.png'
load_image("ExplodeRed", imgExplodeRed);

// Objects
import img_treasure from '../img/objects/chest.png';
load_image("Treasure", img_treasure);
import img_treasure_open from '../img/objects/chest_open.png';
load_image("TreasureOpen", img_treasure_open);
import img_key from '../img/objects/key.png';
load_image("Key", img_key);
import img_speed_potion from '../img/objects/speed_potion.png';
load_image("SpeedPotion", img_speed_potion);
import img_tree from '../img/objects/tree.png';
load_image("Tree", img_tree);
import img_teleporter from '../img/objects/teleporter.png';
load_image("Teleporter", img_teleporter);
import img_teleporter_red from '../img/objects/teleporter_red.png';
load_image("TeleporterRed", img_teleporter_red);
import imgTeleporterGreen from '../img/objects/teleporter_green.png';
load_image("TeleporterGreen", imgTeleporterGreen);
import img_book from '../img/objects/book.png';
load_image("Book", img_book);
import img_machine from '../img/objects/machine.png';
load_image("Machine", img_machine);
import img_bague from '../img/objects/bague.png';
load_image("Bague", img_bague);
import imgTask from '../img/objects/task.png';
load_image("Task", imgTask);
import imgTaskDone from '../img/objects/task_done.png';
load_image("TaskDone", imgTaskDone);
import imgUnfreezePotion from '../img/objects/unfreeze_potion.png';
load_image("UnfreezePotion", imgUnfreezePotion);
import imgHammer from '../img/objects/hammer.png';
load_image("Hammer", imgHammer);



// Puzzles
import imgLeaf from '../img/puzzles/leaf.png';
load_image("PuzzleLeaf", imgLeaf);
import imgBeetle from '../img/puzzles/beetle.png';
load_image("PuzzleBeetle", imgBeetle);
import imgTrash from '../img/puzzles/trash.png';
load_image("PuzzleTrash", imgTrash);

export function try_draw(ctx: CanvasRenderingContext2D, key: string, x: number, y: number){
    const img = images.get(key);
    if (img){
        ctx.drawImage(img, x,y);
    }
}

export function tryDrawCenter(ctx: CanvasRenderingContext2D, key: string, x: number, y: number){
    const img = images.get(key);
    if (img){
        ctx.drawImage(img, x- img.width/2, y - img.height/2);
    }
}