"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Vect = void 0;
class Vect {
    constructor(x, y) {
        this.x = x;
        this.y = y;
    }
    /**
     * Returns a Vect which is a copy of `this`.
     */
    clone() {
        return new Vect(this.x, this.y);
    }
    /**
     * Returns the norm of `this`.
     */
    norm() {
        return Math.sqrt(Math.pow(this.x, 2) + Math.pow(this.y, 2));
    }
    /**
     * Copies `other`.
     */
    copy(other) {
        this.x = other.x;
        this.y = other.y;
    }
    translate(v) {
        this.x += v.x;
        this.y += v.y;
    }
    /**
     * Returns the opposite Vect.
     */
    opposite() {
        return new Vect(-this.x, -this.y);
    }
    /**
     * Returns a Vect from `src` to `dest`.
     * @param src
     * @param dest
     */
    static fromCoords(src, dest) {
        return new Vect(dest.x - src.x, dest.y - src.y);
    }
    /**
     * Returns the scalar product between two Vect.
     */
    scalarProd(other) {
        return this.x * other.x + this.y * other.y;
    }
    /**
     * Rotates `this` Vect.
     * @param angle is is radians (3.14 or Math.PI for an half circle)
     */
    rotate(angle) {
        const a = Math.cos(angle) * this.x - Math.sin(angle) * this.y;
        const b = Math.sin(angle) * this.x + Math.cos(angle) * this.y;
        this.x = a;
        this.y = b;
    }
}
exports.Vect = Vect;
