import { World } from "./world";
import ENV from './.env.json';
import { Player } from "./player";
import { GameMap } from "./game_map";
import { fromRawObject, ObjectType } from "./map_object";
import { CaseExplode, CaseType } from "./case";
import { initBackpackDiv, updateBackpackDiv } from "./backpack";
import { setupPlayerSelector } from "./playerSelector";
import { resetCapacityDiv, setupCapacityDiv } from "./capacity_pack";
import { launchMapGenerator } from "./map_generator";
import { Coord } from "2d-coord";
import { launchPuzzleCurve } from "./puzzles/puzzle_curve";
import { checkGamePad } from "./gamepad";
import { playSound, setupAudioElements } from "./audio";

console.log("Connecting to " + "wss://" + ENV.server + ":" + ENV.port);
export const socket = new WebSocket("wss://" + ENV.server + ":" + ENV.port);
export let mega_counter = 1;

setupPlayerSelector();
// launchPuzzleCurve();

// Desactivate the admin buttons
function toggleDisplayAdminButton(strId: string){
  const button = document.getElementById(strId);
  if (button ){
    if (ENV.devmode){
      button.style.display = "block";
    } else {
      button.style.display = "none";
    }
  }
}

function toggleDisplayAdminButtons(){
  toggleDisplayAdminButton("regen");
  toggleDisplayAdminButton("restart");
}

toggleDisplayAdminButtons()



socket.addEventListener('open', function (event) {
  console.log("connection opened")
});



// Wait for receiving myPlayerId to start the game
socket.onmessage = function (event) {
  const msg = JSON.parse(event.data);
  switch (msg.name) {
      case "client_id": {
          console.log("receive client_id: ", msg.data);
          const myId = parseInt(msg.data) as number;
          setup(myId);
          break;
      }
  }
}



let gamepad: Gamepad;

// TODO to remove after test
window.addEventListener("gamepadconnected", function(event: GamepadEvent) {
  console.log(event)
  console.log(event.gamepad);
  const gamepad2 = navigator.getGamepads()[0];
  if (!gamepad2) return;
  gamepad = gamepad2;
  // gamepad = event.gamepad;
  console.log(`Gamepad connected at index ${gamepad.index}: ${gamepad.id}`);
  setInterval(() => {
    if (gamepad.buttons[0].pressed ){
      console.log("A pressed")
    }}
    , 100);
});

let sps = 0;

setInterval(() => {
  console.log("sps: ", sps);
  sps = 0;
},1000)


function setup(myId: number){
    const canvas = document.getElementById('main') as HTMLCanvasElement;
    const ctx = canvas.getContext('2d');

    setupAudioElements();

    const gameProgressDiv = document.createElement("div");
    gameProgressDiv.id = "gameProgress";
    document.body.appendChild(gameProgressDiv);



    if (ctx != null){
      // canvas.width = 600;
      // canvas.height = 600;
      canvas.width = window.innerWidth;// window.innerWidth;
      canvas.height = window.innerHeight; // window.innerHeight;

        setInterval(() => {mega_counter += 1; mega_counter = mega_counter %60}, 100);

        const world = new World(myId, canvas, ctx);
        initBackpackDiv();
        setupCapacityDiv();

        window.addEventListener("gamepadconnected", function(event: GamepadEvent) {
          console.log(event)
          console.log(event.gamepad);
          const gamepad2 = navigator.getGamepads()[0];
          if (!gamepad2) return;
          gamepad = gamepad2;
          // gamepad = event.gamepad;
          console.log(`Gamepad connected at index ${gamepad.index}: ${gamepad.id}`);
          setInterval(() => { checkGamePad(gamepad, world, canvas)}, 100);
        });

        const regen_div = document.getElementById("regen");
        if (regen_div){
          regen_div.onclick = (e) => {
            launchMapGenerator(world);
          };
        }
        const restartButton = document.getElementById("restart");
        if (restartButton){
          restartButton.onclick = (e) => {
            console.log("Send request: restart");
            socket.send(JSON.stringify({ name: "restart"}));
          };
        }
        

        socket.onmessage = function (event) {
          sps += 1;
            const msg = JSON.parse(event.data);
            if (msg.name != "update_guard"){
                console.log("receive: ", msg.name);
            }
            switch (msg.name) {
              case "reset_game_map": {
                const rgm = msg.data;
                const newMap = GameMap.fromRawGameMap(rgm, canvas, ctx);
                world.gameMaps[newMap.mapIndex] = newMap;
                break;
              }
              case "event_break_wall": {
                playSound("break_wall");
                break;
              }
              case "event_win": {
                playSound("win");
                break;
              }
              case "event_open_chest": {
                playSound("open_chest");
                break;
              }
              case "event_freeze_player": {
                playSound("freeze_player");
                break;
              }
              case "event_trigger_trap": {
                playSound("trigger_trap");
                break;
              }
            case "reset_world": {
                world.gameMaps = new Array();
                for (const rgm of msg.data.maps){
                  const newMap = GameMap.fromRawGameMap(rgm, canvas, ctx);
                  world.gameMaps.push(newMap);
                }
          
                world.players = new Map();
                for (const player_id_str in msg.data.players){
                    const player_id = parseInt(player_id_str);
                    const rp = msg.data.players[player_id];
                    const pos = new Coord(rp.pos.x, rp.pos.y);
                    const dir_vect = new Coord(rp.dir_vect.x, rp.dir_vect.y);
                    const caseCoord = new Coord(rp.case_coord.x, rp.case_coord.y);
                    const player = new Player(rp.id, pos, rp.speed, caseCoord, rp.direction, dir_vect, Math.floor(rp.map_index as number), rp.player_type, rp.is_sick, rp.is_frozen, rp.capacity_wall_breaker, rp.can_break_wall,  rp.capacity_cool_down_start, rp.capacity_cool_down_over, world.canvas, world.ctx );
                    
                    player.resetBackpackFromRaw(rp.backpack);
                    world.players.set(player_id, player);
                }

                const myPlayer = world.players.get(world.myId);
                if (myPlayer){
                  updateBackpackDiv(myPlayer);
                  resetCapacityDiv(myPlayer);

                  // update progressMap
                  world.updateGameProgressDiv(gameProgressDiv, myPlayer.mapIndex);
                }
                
                break;
            }
            case "delete_player": {
              const id = parseInt(msg.data);
              world.players.delete(id);
              break;
            }
            case "add_player": {
              const player_id = parseInt(msg.data.id);
              const rp = msg.data;
              const pos = new Coord(rp.pos.x, rp.pos.y);
              const dir_vect = new Coord(rp.dir_vect.x, rp.dir_vect.y);
              const caseCoord = new Coord(rp.case_coord.x, rp.case_coord.y);
              const player = new Player(rp.id, pos, rp.speed, caseCoord, rp.direction, dir_vect, rp.map_index, rp.player_type, rp.is_sick, rp.is_frozen, rp.capacity_wall_breaker, rp.can_break_wall, rp.capacity_cool_down_start, rp.capacity_cool_down_over, world.canvas, world.ctx );
              world.players.set(player_id, player);
              break;
            }
            case "update_player_data": {

              const data = msg.data;
              const player = world.players.get(parseInt(data.id));
              if (player){
                if (player.id == world.myId){
                  if (player.mapIndex != data.map_index){
                    world.updateGameProgressDiv(gameProgressDiv, data.map_index);
                  }
                  // if ( player.is_frozen == false && data.is_frozen ){
                  //   playSound("game_over");
                  // }
                }

                player.pos.x = data.pos.x;
                player.pos.y = data.pos.y;
                player.case_coord.x = data.case_coord.x;
                player.case_coord.y = data.case_coord.y;
                player.dir_vect.x = data.dir_vect.x;
                player.dir_vect.y = data.dir_vect.y;
                player.is_mouse_moving = data.is_mouse_moving;
                player.mapIndex = data.map_index;
                player.player_type = data.player_type;
                player.is_frozen = data.is_frozen;
                player.is_sick = data.is_sick;
                player.capacity_wall_breaker = data.capacity_wall_breaker;
                player.can_break_wall = data.can_break_wall;

                if (player.id == world.myId){
                  resetCapacityDiv(player);
                }

              }
              break;
            }
            case "update_guard" : {
              const myPlayer = world.players.get(world.myId);
              if (myPlayer){
                const gameMap = world.gameMaps[msg.data[2]];
                const guard = gameMap.guards[msg.data[1]];
                const raw_guard = msg.data[0];

                const pos = new Coord(raw_guard.pos.x, raw_guard.pos.y);
                const caseCoord = new Coord(raw_guard.case_coord.x, raw_guard.case_coord.y);
                const target = new Coord(raw_guard.target.x, raw_guard.target.y);
                guard.pos = pos;
                guard.target = target;
                guard.caseCoord = caseCoord;
                guard.path = raw_guard.path;
              }
             
            break;
          }
          case "discover": {
            const i = Math.floor(msg.data[0].x);
            const j = Math.floor(msg.data[0].y);
            world.gameMaps[msg.data[1]].cases[i][j].discovered = true;
            break;
          }
          case "change_case_explode_state": {
            if ( world.gameMaps[msg.data[0]].cases[msg.data[1]][msg.data[2]].case_type == CaseType.Explode){
              const c = world.gameMaps[msg.data[0]].cases[msg.data[1]][msg.data[2]] as CaseExplode;
              c.state = msg.data[3];
            }
            break;
          }
          case "reset_objects": {
            const map = world.gameMaps[msg.data[0]];
            map.objects = new Array();
            for (const raw_object of msg.data[1]){
              const new_object = fromRawObject(raw_object);
              if (new_object){
                map.objects.push(new_object);
              }
            }

            const myPlayer = world.players.get(world.myId);
            if (myPlayer && msg.data[0] == myPlayer.mapIndex ){
              world.updateGameProgressDiv(gameProgressDiv, msg.data[0]);
            }

            break;
          }
          case "reset_player_backpack": {
            if ( msg.data.id == world.myId) {
              const myPlayer = world.players.get(world.myId);
              if (myPlayer){
                myPlayer.resetBackpackFromRaw(msg.data.backpack);
                updateBackpackDiv(myPlayer);
              }
              
            } 
            break;
          }

          default :{
            console.log("Unsupported msg: ", msg);
            break;
          }

        }
        requestAnimationFrame(function() {world.draw()});        

        }


        // CANVAS INTERACTIONS

        let is_mouse_moving = false;

        canvas.addEventListener("mousedown", function(e){
          const myPlayer = world.players.get(world.myId);
          if (myPlayer){
            const mouse_pos = new Coord(e.pageX - canvas.width/2 + myPlayer.pos.x, e.pageY - canvas.height/2 + myPlayer.pos.y);
            let activated = false;
            if (is_click_near_me(myPlayer, mouse_pos)){
              activated = world.localPlayerRequestActivation();
            }
            if (activated == false) {
              is_mouse_moving = true;
              const audio = document.getElementById("audioWalk") as HTMLAudioElement;
              if (audio) audio.play();
              socket.send(JSON.stringify({ name: "mousedown", x: e.pageX - canvas.width/2 + myPlayer.pos.x, y: e.pageY - canvas.height/2 + myPlayer.pos.y }))
            }
          }
        });
      
        canvas.addEventListener("mousemove", function(e){
          const myPlayer = world.players.get(world.myId);
          if (myPlayer && is_mouse_moving){
            socket.send(JSON.stringify({ name: "mousemove", x: e.pageX  - canvas.width/2 +  myPlayer.pos.x, y: e.pageY - canvas.height/2 + myPlayer.pos.y }))
          }
        });
      
        canvas.addEventListener("mouseup", function(e){
          is_mouse_moving = false;
          socket.send(JSON.stringify({ name: "stopmove"}))
        });



      window.addEventListener('keydown', function (e) {
        let isPuzzleLaunched = false;
        for (const div of this.document.getElementsByClassName("puzzle")){
          isPuzzleLaunched = true;
          break;
        }
        if (isPuzzleLaunched){
          if ( e.key == "Escape"){
            for (const div of this.document.getElementsByClassName("puzzle")){
              div.remove();
            }
          }
          return;
        }

        if ( e.key == "s" && ENV.devmode ){ // ArrowDown before
          socket.send(JSON.stringify({ name: "move", direction: "down" }));
          } else if ( e.key == "d" && ENV.devmode){
          socket.send(JSON.stringify({ name: "move", direction: "right" }));
        } else if ( e.key == "q" && ENV.devmode){
          socket.send(JSON.stringify({ name: "move", direction: "left" }));
        } else if ( e.key == "z" && ENV.devmode){
          socket.send(JSON.stringify({ name: "move", direction: "up" }));
        } else if ( e.key == "f"){
          world.localPlayerRequestActivation();
        } else if ( e.key == "r"){
          socket.send(JSON.stringify({ name: "drop" }));
        } else if ( e.key == "h"){
          ENV.devmode = !ENV.devmode;
          toggleDisplayAdminButtons();
        } else if ( e.key == "Escape"){
          const mapGenerator = this.document.getElementById("mapGenerator");
          if (mapGenerator !== null){
            mapGenerator.remove();
          }
          const bookPopUp = this.document.getElementById("book");
          if (bookPopUp !== null){
            bookPopUp.remove();
          }
          for (const div of this.document.getElementsByClassName("puzzle")){
            div.remove();
          }
        }
      })

      window.addEventListener("keyup", function(e){
        socket.send(JSON.stringify({ name: "stopmove"}))
      })

        
    }
    
}







function is_click_near_me(myPlayer: Player, p: Coord){
  return (myPlayer.pos.x - p.x)**2 + (myPlayer.pos.y - p.y)**2 <= 400
}

