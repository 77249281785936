import { Coord, isSegmentsIntersection, Vect } from "2d-coord";
import { socket } from "../app";
import { DragContainer } from "./drag_container";

const MAX_DISTANCE = 12;

let isPuzzleOver = false;


function drawCurve(curve: Array<Coord>, canvas: HTMLCanvasElement){
    const ctx = canvas.getContext("2d");
    if (ctx){
        ctx.beginPath();
        ctx.moveTo(curve[0].x, curve[0].y);
        for (let i = 1 ; i < curve.length ; i ++){
            ctx.lineTo(curve[i].x, curve[i].y); 
        }
        ctx.strokeStyle = "gray"; 
        ctx.lineWidth = 2; 
        ctx.stroke(); 
    }
}

function checkAutoIntersection(curve: Array<Coord>): boolean {
    const n = curve.length;
    for(let i = 0 ; i < n ; i ++){
        for (let j = i+2 ; j < n-1 ; j ++){
            if (isSegmentsIntersection(curve[i], curve[i+1], curve[j],curve[j+1])){
                // console.log(i,j);
                return true;
            }
        }
    }
    return false;
}

function createRandomCurve(n: number, w: number, h: number): Array<Coord>{
    const curve = new Array<Coord>();
    for (let i = 0 ; i < n ; i ++){
        while(true){
            curve.push(new Coord(Math.floor(Math.random()*w), Math.floor(Math.random()*h) ));
            if ( checkAutoIntersection(curve)){
                console.log(i);
                curve.pop();
            } else {
                break;
            }
        }
    }
    return curve
}


/**
 * Create a random polygonal curve by choosing at each step a quasi orthogonal direction to the previous one.
 */
function createRandomCurve2(n: number, w: number, h: number): Array<Coord>{
    const curve = new Array<Coord>();

    curve.push(new Coord(w/2, h/2));
    let theta = Math.random()*2*3.14;
    let lastDir = new Vect(Math.cos(theta),Math.sin(theta));
    const newCoord = curve[0].clone();
    newCoord.translateF(lastDir,100);
    curve.push(newCoord);


    for (let i = 2 ; i < n ; i ++){
        console.log(i);
        let nbTry = 0;
        while(true){
            
            // const theta = Math.random()*2*3.14;
            
            const bin = Math.floor(Math.random()*2);
            if (bin == 0){
                theta = Math.PI/2 + ((Math.random()-0.5)*0.4);
            } else {
                theta = -Math.PI/2 + ((Math.random()-0.5)*0.4);
            }
            
            const dir = lastDir.clone();
            dir.rotate(theta);
            const newCoord = curve[i-1].clone();
            
            newCoord.translateF(dir, 40 + Math.random()*60);
            if (!newCoord.isInRect(new Coord(0,0), new Coord(w,h))){
                continue;
            }
            curve.push(newCoord);
            nbTry ++;
            if ( nbTry < 10 && checkAutoIntersection(curve)){
                console.log("intersect");
                curve.pop();
            } else {
                lastDir = dir.clone();
                break;
            }
        }
    }
    return curve
}


function distanceToCurve(point: Coord, curve: Array<Coord>){
    let minDist = 1000;
    for (let i = 0 ; i < curve.length-1 ; i ++){
        const d = point.distToSegment(curve[i],curve[i+1]);
        if ( d < minDist){
            minDist = d;
        }
    }
    return minDist;
}


export function launchPuzzleCurve(){
    isPuzzleOver = false;
    const div = document.createElement("div");
    document.body.appendChild(div);
    div.classList.add("puzzle");

    const canvas = document.createElement("canvas");
    canvas.width = 400;
    canvas.height = 400;
    div.appendChild(canvas);
    canvas.id = "puzzleCurveCanvas";

    const curve = createRandomCurve2(10, 400, 400);
    drawCurve(curve, canvas);



    const distBar = document.createElement("div");
    distBar.id = "distBar";
    div.appendChild(distBar);
    const distBarJauge = document.createElement("div");
    distBarJauge.id = "distBarJauge";
    distBar.appendChild(distBarJauge);

    const container = new DragContainer(div);
    // container.addImage("PuzzleBeetle", "beetle", curve[0].clone());

    const whaleDiv = document.createElement("div");
    whaleDiv.classList.add("puzzleCurveWhale");
    container.add(whaleDiv, curve[0].clone());
    
    container.afterMouseMove = () => {
        const b = container.elements[0];
        const distance = distanceToCurve(b.pos, curve);
        if ( isPuzzleOver == false && distance > MAX_DISTANCE){
            isPuzzleOver = true;
            container.div.classList.add("failed");
            setTimeout(() => {div.remove()}, 1000);
            socket.send(JSON.stringify({ name: "puzzle_fail" }));
            
        }
        
        if ( isPuzzleOver == false && b.pos.distTo(curve[curve.length-1]) <= MAX_DISTANCE ){
            isPuzzleOver = true;
            div.classList.add("achieved");
            setTimeout(() => div.remove(), 1000);
            socket.send(JSON.stringify({ name: "puzzle_achieve" }));
        }
        let ratio = distance*100/MAX_DISTANCE;
        if (ratio >= 100){
            ratio = 100;
        }
        distBarJauge.style.height = ratio.toString();
    }



}