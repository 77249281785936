// Puzzle: Grid
/*
Goal: turn off all the lights.
By clicking on a square, it inverts the state of this square and all the square around this one, according to the cross (+) adjacencies.

The puzzle initiates alone with nbInitMoves random moves.

Developper options: 
- change the nbInitMoves
- change the adjacencies
*/



import { socket } from "../app";
import puzzleImages from '../../img/puzzles/*.png';

// Parameters

const nbInitMoves = 2;

// Cross
const neighborsCross = [[0,0],[-1,0],[1,0],[0,1],[0,-1]];

// Cavalier
// const neighborsCross = [[0,0],[-2,1],[2,1],[-2,-1],[2,-1],[1,2],[1,-2],[-1,2],[-1,-2]];

// King
// const neighborsCross = [[0,0],[0,1],[0,-1],[1,0],[1,1],[1,-1],[-1,0],[-1,1],[-1,-1]];





let isPuzzleOver = false;

function achievePuzzle(div: HTMLDivElement){
    if (isPuzzleOver == false){
        isPuzzleOver = true;
        div.classList.add("achieved");
        setTimeout(() => div.remove(), 1000);
        socket.send(JSON.stringify({ name: "puzzle_achieve" }));
    }
}

function failPuzzle(div: HTMLDivElement){
    if ( isPuzzleOver == false){
        isPuzzleOver = true;
        div.classList.add("failed");
        setTimeout(() => {div.remove()}, 1000);
        socket.send(JSON.stringify({ name: "puzzle_fail" }));
    }
}




function tryAddNeighbor(neighbors: Array<Array<number>>,i: number,j: number,n: number){
    if (i >= 0 && j >= 0 && i < n && j < n){
        neighbors.push([i,j]);
    }
}



function closedNeighborsCross(i: number,j: number,n: number): Array<Array<number>> {
    const neighbors = new Array();
    for (const p of neighborsCross){
        const x = i+p[0];
        const y = j+p[1];
        tryAddNeighbor(neighbors, x,y,n);
    }
    return neighbors;
}

function invertCross(grid: Array<Array<HTMLDivElement>>, i: number, j: number){
    let n = grid.length;
    let neighbors = closedNeighborsCross(i,j,n);
    for (const neighbor of neighbors){
        invertSquareColor(grid[neighbor[0]][neighbor[1]]);
    }
}


function invertSquareColor(square: HTMLDivElement){
    if (square.classList.contains("lightOffSquare")){
        square.classList.remove("lightOffSquare");
        square.classList.add("lightOnSquare");
        square.innerHTML = "";
        
        const torchImg = document.createElement("img");
        torchImg.src = puzzleImages["torch_on"];
        torchImg.style.width = "60px";
        square.appendChild(torchImg);

    } else {
        square.classList.remove("lightOnSquare");
        square.classList.add("lightOffSquare");
        square.innerHTML = "";

        const torchImg = document.createElement("img");
        torchImg.src = puzzleImages["torch_off"];
        torchImg.style.width = "60px";
        square.appendChild(torchImg);
    }
}

/**
 * Returns true if all the lights are Off.
 */
function checkGrid(grid: Array<Array<HTMLDivElement>> ){
    for (let i = 0 ; i < grid.length; i++){
        for (let j = 0 ; j < grid.length; j ++){
            if (grid[i][j].classList.contains("lightOnSquare")){
                return false;
            }
        }
    }
    return true;
}



export function launchPuzzleGrid(){
    const div = document.createElement("div");
    document.body.appendChild(div);
    div.classList.add("puzzle");
    div.classList.add("lightsPuzzle");

    isPuzzleOver = false;
  
    const n = 3;
    div.style.gridTemplateColumns = "repeat(" + n + ", 110px)";
    div.style.gridTemplateRows = "repeat(" + n + ", 110px)";


    const grid: Array<Array<HTMLDivElement>> = new Array();
    for (let i = 0 ; i < n ; i ++){
        grid.push( new Array());
        for (let j = 0 ; j < n ; j ++){
            const square = document.createElement("div");
            div.appendChild(square);
            square.classList.add("puzzle-item-square");
            grid[i].push(square);
            square.classList.add("lightOffSquare");

            const torchImg = document.createElement("img");
            torchImg.src = puzzleImages["torch_off"];
            torchImg.style.width = "60px";
            square.appendChild(torchImg);

            square.onclick = function () {
                invertCross(grid, i, j);
                if (checkGrid(grid)){
                    achievePuzzle(div);
                }
              }
        }
    }


    // initialize
    while (checkGrid(grid)){
        for ( let k = 0 ; k < nbInitMoves ; k ++){
            let i = Math.floor(Math.random()*n);
            let j = Math.floor(Math.random()*n);
            invertCross(grid, i, j);
        }
    }

  
}