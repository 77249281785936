import { Coord } from "2d-coord";
import { socket } from "../app";
import { DragContainer } from "./drag_container";

let isPuzzleOver = false;

function areEltsNear(container: DragContainer, elementClass: string, target: Coord, dist: number): boolean{
    for (const elt of container.elements){
        if (elt.div.classList.contains(elementClass)){
            if (elt.pos.distTo(target) >= dist){
                return false;
            }
        }
    }
    return true;
}

function achievePuzzle(div: HTMLDivElement){
    if (isPuzzleOver == false){
        isPuzzleOver = true;
        div.classList.add("achieved");
        setTimeout(() => div.remove(), 1000);
        socket.send(JSON.stringify({ name: "puzzle_achieve" }));
    }
}

export function launchPuzzleSort(){
    isPuzzleOver = false;

    const div = document.createElement("div");
    document.body.appendChild(div);
    div.classList.add("puzzle");

    // width of rectangular bucket is 100
    const bucket1Pos = new Coord(100,100);
    const divBucket1 = document.createElement("div");
    divBucket1.classList.add("bucket");
    divBucket1.style.top = "0px";
    divBucket1.style.left = "0px";
    divBucket1.style.backgroundColor = "#432144";
    div.appendChild(divBucket1);

    const bucket2Pos = new Coord(400,400);
    const divBucket2 = document.createElement("div");
    divBucket2.classList.add("bucket");
    divBucket1.style.top = "300px";
    divBucket1.style.left = "300px";
    divBucket2.style.backgroundColor = "#123456";
    div.appendChild(divBucket2);

    // Container
    const container = new DragContainer(div);
    let nbLeaves = 0;
    let nbBeetles = 0;
    let nbTrash = 0;
    for (let i = 0 ; i < 10 ; i++){
        const x = 100 + Math.floor(Math.random()*200);
        const y = 100 + Math.floor(Math.random()*200);
        const r = Math.random();
        if (r < 0.33){
            container.addImage("PuzzleLeaf", "leaf", new Coord(x,y));
            nbLeaves ++;
        } else if (r < 0.66) {
            container.addImage("PuzzleBeetle", "beetle", new Coord(x,y));
            nbBeetles ++;
        } else {
            container.addImage("PuzzleTrash", "trash", new Coord(x,y));
            nbTrash ++;
        }
        
        container.lastElt().div.style.transform = "rotate(" + Math.floor(Math.random()*360) + "deg)";
    }

    container.afterMouseMove = () => {
        if (nbLeaves == 3){
            if (areEltsNear(container, "leaf", bucket1Pos, 100)){
                achievePuzzle(div);
            }
        } else if (nbTrash == 0){
            if (areEltsNear(container, "beetle", bucket2Pos, 100)){
                achievePuzzle(div);
            }
        } else if (nbBeetles < nbLeaves){
            if (areEltsNear(container, "leaf", bucket1Pos, 100) && areEltsNear(container, "beetle", bucket2Pos, 100)){
                achievePuzzle(div);
            }
        } else {
            if (areEltsNear(container, "trash", bucket2Pos, 100)){
                achievePuzzle(div);
            }
        }
        

        

    }

}